@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@300&display=swap');

/*
	Step progress Bar 
 */
.multi-steps > li.is-active:before,
.multi-steps > li.is-active ~ li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 700;
}

.multi-steps > li.is-active:after,
.multi-steps > li.is-active ~ li:after {
  background-color: #a1a1a1;
}

.multi-steps {
  //margin-left: -8%;
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 175px;
  margin: 3rem auto;
  padding: 0;
}

.multi-steps > li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  color: $main;
}
.multi-steps > li:before {
  position: relative;
  content: '\f00c';
  content: '\2713;';
  content: '\10003';
  content: '\10004';
  content: '\2713';
  display: block;
  margin: 0 auto 4px;
  color: #fff;
  background-color: $main;
  width: 36px;
  height: 36px;
  line-height: 32px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: $main;
  border-radius: 50%;
  z-index: 10;
}
.multi-steps > li:after {
  content: '';
  height: 4px;
  width: 100%;
  background-color: $main;
  position: absolute;
  top: 16px;
  left: 50%;
  z-index: 0;
}
.multi-steps > li:last-child:after {
  display: none;
}
.multi-steps > li.is-active:before {
  background-color: $main;
  border-color: $main;
}
.multi-steps > li.is-active ~ li {
  color: #808080;
}
.multi-steps > li.is-active ~ li:before {
  background-color: #a1a1a1;
  border-color: #a1a1a1;
}

.gift-form-top {
  position: relative;
  width: 100%;
  background: url(/img/img-gift-cards-desktop-banner.jpg) no-repeat center
    center;
  background-size: cover;
  @include smallScreen() {
    background-image: url(/img/img-gift-cards-mobile-banner.jpg);
  }
}

/*
.gift-cards-selection-container-parent{
  position:relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  min-height:100vh;
  background:url(/img/img-gift-cards-desktop-banner.jpg) no-repeat center center;
  background-size:cover;
  text-align: left;
  @include smallScreen() {
    background-image:url(/img/img-gift-cards-mobile-banner.jpg);
    text-align: center;
    align-items: center;
    padding-bottom:15rem;
  }
}


.gift-cards-selection-container-child{
  margin-top: 20px;
  padding: 100px;
 
  
}

.gift-cards-formtitle{
  
  color: white;
  text-align: center;
  font-size: 15px;
  letter-spacing: .0125em;
  font-family: $fontSecondary;
  
 

}

.gift-cards-form {
   align-items: stretch;
}

.gift-cards-cardContainer{
  background: #212120;
  margin: 30px;
  padding: 30px;
  height: 300px;
  width: 300px;

  font-size: 20px;
  border-radius:5px;
  font-family: $fontSecondary;
  flex-direction: column;
  align-items: top;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.5), -12px 10px 12px rgba(0, 0, 0, 0.35);
}


.gift-cards-cardContainer h1 {
    text-align: center;
    color: white;
    font-family: $fontSecondary;
    &.mod-s{
      
      font-size: .7em;
      letter-spacing: .0125em;
    }
    &.mod-l{
      
        font-size: 1em;
        letter-spacing: .075em;
      }

}

.gift-cards-cardContainer input{
    background-color: #ffffff;
    color: black;
    
}

.gift-cards-cardContainer label{
   color: white;
    
    
}

.gift-cards-cardContainer select{
    color:#212120 ;
    background-color: white;
}

.gift-cards-cardContainer textarea {
    
}
.gift-cards-tag{
    text-align: center;
    color: white;
    font-family: $fontSecondary;
    &.mod-s{
      font-size: .7em;
      letter-spacing: .0125em;
    }
    &.mod-m{
      font-family: "BebasNeueLight";
      font-size: .8em;
      letter-spacing: .0125em;
    }
    &.mod-l{
      font-size: 1em;
      letter-spacing: .075em;
    }
  }
.spacer{
  height: 30px;
}
*/
