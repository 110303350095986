$black: #000000;
$white: #ffffff;
$main: #587d62;
$gray: #6e6f72;
$darkgray: #4a4a4a;
$lightergray: #eeeeee;
$lightgray: #c0c0c0;
$gray100: #919191;
$whitebg: #f5f5f5;
$lightgraybg: #fafafa;
$lightgreen: #93bc9e;
$lightblue: #ebeff2;
$yellow: #f6cd16;

/* Text Colors */
$primary-text: #4a4a4a;

/* Font Sizes */
$smallerFont: 1rem;
$smallFont: 1.5rem;
$normalFont: 1.6rem;
$mediumFont: 2rem;
$largeFont: 2.5rem;
$bigFont: 3.7rem;
$hugeFont: 7.5rem;

$smallerFontMobile: 0.675rem;
$smallFontMobile: 1.25rem;
$normalFontMobile: 1.3rem;
$mediumFontMobile: 1.75rem;
$largeFontMobile: 2.15rem;
$bigFontMobile: 3rem;

/* Font Family */
$fontPrimary: 'Josefin Sans', sans-serif;
$fontSecondary: 'BebasNeue', sans-serif;

/* Image Loading Icon */
$ImgloadingIcon: '/img/icon-img-loading.gif';

@font-face {
  font-family: 'Montserrat-Light';
  src:
    local('Montserrat-Light'),
    url('/fonts/Montserrat-Light.otf') format('truetype');
}

@font-face {
  font-family: 'BebasNeue';
  src:
    local('BebasNeue'),
    url('/fonts/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'BebasNeueRegular';
  src:
    local('BebasNeueRegular'),
    url('/fonts/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'BebasNeueLight';
  src:
    local('BebasNeueLight'),
    url('/fonts/BebasNeue-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans';
  src:
    local('Josefin Sans'),
    url('/fonts/JosefinSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans; Thin';
  src:
    local('Josefin Sans; Thin'),
    url('/fonts/JosefinSans-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'GenJyuuGothic-Bold';
  src:
    local('GenJyuuGothic-Bold'),
    url('/fonts/GenJyuuGothic-Bold.ttf') format('truetype');
}
