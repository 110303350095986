@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';
@import '../../styles/mixins.scss';

.blog-container {
  font-family: 'Josefin Sans', sans-serif;
  width: 100%;
  iframe {
    height: 3200px;
    @media (max-width: 1023px) {
      height: 3800px;
    }
    @media (max-width: 640px) {
      height: 6500px;
    }
    @media (max-width: 480px) {
      height: 5700px;
    }
  }
}
.blog-banner {
  margin-top: 30px;
  width: 100%;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $main;
  color: #fff;
  font-size: 45px;
}
.blog {
  text-align: center;
}
