@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.popup-label {
  font-family: 'Josefin Sans', sans-serif;
  font-size: $normalFont;
  color: $darkgray;
}

.popup-bottom-margin {
  margin-bottom: 1rem;
}

.bold {
  font-weight: bold;
}

.popup-label-small {
  font-family: 'Josefin Sans', sans-serif;
  font-size: $smallFont;
  color: $darkgray;
}

.bottom-border {
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  border-bottom: $lightgray solid 0.1rem;
}

.details-popup-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem 5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  z-index: 500;

  .details-popup-card {
    background-color: $white;
    width: 100%;
    padding: 3rem;
    position: relative;

    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      & + .popup-list-container {
        margin-top: 0;
      }
    }

    .popup-title {
      flex: 0.8;
    }

    .close-button {
      position: absolute;
      top: 2rem;
      right: 2rem;
      color: $darkgray;
    }

    p {
      color: $darkgray;
      font-size: $smallerFont;
      font-weight: bold;
      font-family: 'Josefin Sans', sans-serif;
    }

    .exclusion-header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .exclusion-header-item {
        border-left: $lightgray solid 0.1rem;
        margin-left: 2rem;
        padding-left: 2rem;
      }
    }

    .popup-list-container {
      width: 90%;
      margin: 2rem 0;
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .exclusion-item {
        //flex: 1 0 21%;
        margin: 0.5rem 0;
        width: 25%;
        @include largeScreen() {
          width: 50%;
        }
        @include mediumScreen() {
          width: 100%;
        }
        @include smallScreen() {
          width: 100%;
        }
      }

      .protein-change-item {
        flex: 1;
      }
    }

    .row {
      flex-direction: row;
      display: flex;
    }

    .no-extra-charge {
      padding-left: 1rem;
      margin-left: 1rem;
      border-left: $lightgray solid 0.1rem;
      font-weight: normal;
    }

    .popup-bottom-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-self: flex-start;

      .total-extra-charge-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .popup-label {
          margin-right: 2rem;
        }
      }

      button {
        padding: 1.5rem;
        background-color: $main;
        color: $white;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: bold;
        font-size: $normalFont;
      }

      button:hover {
        transition: 0.3s;
        background-color: #79b9b0;
      }
    }
  }
}

@include mobileView() {
  .details-popup-container {
    padding: 1rem;

    .details-popup-card {
      padding: 1rem;

      p {
        color: $darkgray;
        font-size: $smallerFont;
        font-weight: bold;
        font-family: 'Josefin Sans', sans-serif;
      }

      .exclusion-header {
        display: flex;
        flex-direction: row;

        .exclusion-header-item {
          margin-left: 0.5rem;
          padding-left: 0.5rem;
        }
      }

      .popup-list-container {
        flex-direction: column;

        .protein-change-item {
          margin: 0.5rem 0;
          margin-right: 0.5rem;
        }
      }

      .popup-bottom-container {
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;

        .total-extra-charge-container {
          flex-direction: column;
          align-items: flex-start;
          line-height: 1.5;
        }

        button {
          padding: 1.5rem;
          background-color: $main;
          color: $white;
          font-family: 'Josefin Sans', sans-serif;
          font-weight: bold;
          font-size: $normalFont;
        }

        button:hover {
          transition: 0.3s;
          background-color: #79b9b0;
        }
      }
    }
  }
}
