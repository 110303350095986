@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';
@import '../../../../styles/mixins.scss';

.plan-menu-preview {
  position: relative;
  text-align: left;
  margin-top: 5rem;
  text-align: center;
  @include smallScreen() {
    text-align: left;
  }
}
.plan-menu-preview-title {
  font-family: $fontSecondary;
  font-size: $largeFont;
  color: $gray100;
}
.plan-menu-preview-thumb {
  width: 100%;
  padding-bottom: 100%;
  @include image-loading-style;
}
.plan-menu-preview-thumb-src {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: no-repeat center center;
  background-size: cover;
}

.plan-menu-preview .swiper-control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  .swiper-button-prev,
  .swiper-button-next {
    position: relative;
    transform: none;
    margin: 0;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    font-size: 0;
    color: $primary-text;
    cursor: pointer;
    &:after {
      font-size: 2rem;
    }
  }
}
.plan-menu-preview-slider {
  max-width: 400px;
  margin: 0 auto;
  @include smallScreen() {
    margin: 0;
  }
}
.plan-menu-preview-box {
  width: 100%;
  padding: 1rem;
  border: 1px solid $lightgray;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.plan-menu-preview-img {
  width: 90px;
  flex: 0 0 auto;
}
.plan-menu-preview-txt {
  width: 100%;
  padding-left: 2rem;
  text-align: left;
  &:first-child {
    padding-left: 0;
  }
}
.plan-menu-preview-name {
  margin: 0 0 0.5rem;
}
.plan-menu-preview-calories {
  margin: 0;
  //white-space: nowrap;
}

.plan-menu-preview-label {
  display: flex;
  align-items: center;
  font-family: $fontSecondary;
  font-size: $mediumFont;
  font-weight: 700;
  margin: 0.4rem 1rem 0;
}

.plan-menu-preview-date {
  // font-size: $smallFont;
}
.plan-menu-preview-type {
  color: $main;
  margin-left: 1rem;
}
