@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';

.submenu {
  position: relative;
}
.submenu-toggle {
  font-family: 'Montserrat-Light';
  font-weight: bold;
  font-size: 1.5rem;
  color: $primary-text;
  padding: 0;
  .bm-menu & {
    color: #fff;
    font-size: 1.75rem;
    line-height: 3;
  }
}
.submenu-items {
  display: none;
  background: #fff;
  position: absolute;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 2.5rem 2.5rem;
  top: 100%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1rem;
  .submenu.mod-open & {
    display: block;
  }
  a {
    display: block;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0;
    }
    .bm-menu & {
      padding-left: 1.5rem;
      &:before {
        content: '>';
        display: block;
        position: absolute;
        left: 0;
      }
    }
  }
  .bm-menu & {
    background: transparent;
    position: relative;
    box-shadow: none;
    left: auto;
    transform: none;
    text-align: left;
    margin-top: 0;
    padding: 0 0 1rem;
  }
}
