@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';
@import '../../styles/mixins.scss';

.package-item {
  position: relative;
  height: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  margin: 10px 0;
  //opacity:0.4;
  cursor: pointer;
  // &.is-selected{
  //   opacity:1;
  // }
}
.package-selector {
  opacity: 0;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.duration-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $bigFont;
  color: $white;
  height: $bigFont;
  line-height: $bigFont;
  padding: 1.5rem 0 1rem;
  width: 100%;
  background-color: $main;
  font-family: $fontSecondary;
  pointer-events: none;
  display: none;

  &.mod-select {
    display: flex;
    position: absolute;
    font-size: $smallFont;
    width: 160px;
    z-index: 1010;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    line-height: initial;
    pointer-events: all;
    bottom: 15px;
    @include smallScreen() {
      bottom: 12px;
    }
  }

  .lang-zh & {
    font-size: $largeFont + 0.4rem;
  }
  .package-item.is-selected & {
    pointer-events: all;
  }

  .select {
    position: relative;
    z-index: 1000;
    height: 100%;
    width: 100%;
    cursor: pointer;
    text-align: center;
    text-align-last: center;
    font-size: $smallFont;
    font-family: $fontPrimary;
    font-weight: bold;
    color: $darkgray;
    .lang-zh & {
      font-size: $largeFont + 0.4rem;
      margin-top: -0.75rem;
    }

    &__control {
      background-color: transparent;
      border: none;
      height: 100%;
      box-shadow: none;
      min-height: 0;
      padding: 3px 0;
    }

    &__option {
      background-color: $white;
      color: $gray100;
      font-size: $mediumFont;

      &:hover {
        background-color: $lightblue;
        color: $darkgray;
      }
    }

    &__menu {
      // position: relative;
      z-index: 1000;
      margin: 0;
    }

    &__value-container {
      padding: 0;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-top: 5px;
    }

    &__placeholder,
    &__single-value {
      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-left: 1rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #fff transparent transparent transparent;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -5px;
      }
    }

    &__placeholder {
      margin: 0;
      width: 100%;
      height: 100%;
      color: #fff;
    }
    .select__single-value {
      text-align: center;
      color: #fff;
      padding: 0;
      height: 100%;
    }
  }
  .select__trigger {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 0;
    user-select: none;
    z-index: 1001;
  }
}
.package-item-container {
  height: 85%;
  padding: 45px;
  background-image: url('/img/bg_wood.png');
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    opacity: 0;
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.package-item-content {
  height: 100%;
  width: 100%;
  padding: 8% 8% 5%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  background-color: $white;
  box-sizing: border-box;
  //overflow: scroll;
}

.package-item-title {
  //margin-top: 2rem;
  .steps-container & {
    margin: 0 0 1rem;
  }
}

.package-item-list {
  position: relative;
  padding: 0 1rem;
  list-style-type: none;

  li {
    position: relative;
    padding-left: 1.5rem;
    font-size: 1.3rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;

    &::before {
      content: '-';
      display: block;
      position: absolute;
      left: 0;
      top: 0.0125rem;
    }
  }
}
.package-item-thumb {
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}

.slider-container {
  @include m-swiper-slider();

  @include mobileView() {
    &.hide-mobile {
      display: none !important;
    }
  }
}

.package-item-best-seller {
  position: absolute;
  width: 33.3333%;
  max-width: 100px;
  top: 0;
  right: 15px;
  background: url(/img/icon-best-seller.png) no-repeat center top;
  background-size: contain;
  &:before {
    content: '';
    position: relative;
    display: block;
    padding-bottom: 135%;
  }
}

.package-thumb {
  width: 100%;
  // padding-bottom: 100%;
  padding-bottom: 35.9712%;
  @include image-loading-style;
}
.package-thumb-src {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: no-repeat center center;
  background-size: cover;
}

.slider-static-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  @include mobileView() {
    max-width: 640px;
  }
  @include smallScreen() {
    max-width: 320px;
  }
  // margin-left:-15px;
  // margin-right:-15px;
  .slider-static-item {
    position: relative;
    width: 33.3333%;
    padding: 3px 15px 0;
    box-sizing: border-box;
    font-size: 0;
    @include mediumScreen() {
      width: 50%;
    }
    @include smallScreen() {
      width: 100%;
    }
    .package-item {
      height: auto;
    }
  }
}
