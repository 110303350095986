@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.checkbox-button-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  &:first-child {
    padding-left: 0;
  }

  .checkbox-icon {
    color: $main;
  }

  .checkbox-icon-disabled {
    color: lightcoral;
  }

  .checkbox-label {
    font-size: $normalFont;
    margin: 0 1rem;
    color: $darkgray;
    font-family: 'Josefin Sans', sans-serif;
    @include smallScreen() {
      font-size: $normalFontMobile;
      margin-left: 0.5rem;
      margin-right: 1.5rem;
    }
  }
}
