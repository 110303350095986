@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.keto-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: table;

  &.mod-invisible {
    display: none !important;
    pointer-events: none;
    z-index: -1;
  }
}

.keto-popup-body {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.keto-popup-body-inner {
  background: #fff;
  display: block;
  width: 90%;
  max-width: 520px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 2rem;
  box-sizing: border-box;
  @media (max-width: 560px) {
    max-width: 350px;
  }
  @media (max-width: 349px) {
    max-width: 320px;
  }
}

.keto-popup-content {
  display: none;

  &.mod-active {
    display: block;
  }
}

.keto-popup-scroller {
  max-height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.keto-popup-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.keto-popup-close {
  position: relative;
  $size: 20px;
  width: $size;
  height: $size;
  font-size: 0;
  padding: 0;
}

.keto-popup-close-graphic {
  position: relative;
  display: block;
  $size: 100%;
  width: $size;
  height: $size;
  transform: rotate(45deg);

  &:before,
  &:after {
    content: '';
    $height: 2px;
    position: absolute;
    display: block;
    width: 100%;
    height: $height;
    background: $main;
    top: 50%;
    margin-left: -$height/2;
  }

  &:before {
    transform: rotate(90deg);
  }
}

.keto-popup-back {
  padding: 0;
  color: $lightgray;
}

.keto-popup-title {
  text-align: center;
  margin: 0 0 2rem;
}
.keto-popup-desc {
  font-size: $normalFontMobile;
}

.keto-popup-btns {
  text-align: center;
  padding: 1rem;
  margin-top: 1rem;
}

.keto-popup-btn,
.keto-popup-item-btn {
  display: inline-block;
  font-size: $smallFontMobile;
  font-family: $fontPrimary;
  font-weight: bold;
  // font-family: $fontSecondary;
  // letter-spacing: 0.1rem;
  font-weight: bold;
  color: $white;
  background-color: $main;
  padding: 0.65rem 1.5rem 0.5rem;
  border: none;
  margin-right: 1.5rem;
  white-space: nowrap;
  &:last-child {
    margin-right: 0;
  }

  @include largeScreen() {
    font-size: $smallerFont;
    padding: 0.5rem 1rem;
  }

  @include mobileView() {
    font-size: $smallerFont;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
  }
}

.keto-popup-btn {
  font-size: $smallFontMobile;
  padding: 0.75rem 1.5rem;
  // margin-left: 0;
  // margin-right: 0;

  @include largeScreen() {
    font-size: $smallFontMobile;
  }

  @include mobileView() {
    font-size: $smallFontMobile;
  }

  &.mod-yellow {
    background-color: $yellow;
    color: $black;
  }
}
