@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.plan-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000, 0.5);
  z-index: 1000;
}
.plan-popup-overlay-close {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  font-size: 0;
}
.plan-popup-body {
  position: absolute;
  width: 80%;
  //height:80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  max-width: 800px;
  max-height: 480px;
  padding: 8rem 5rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 50;
}
.plan-popup-close {
  position: absolute;
  right: 2rem;
  top: 2rem;
}
.plan-popup-container {
  width: 100%;
  text-align: center;
  max-height: 100%;
  overflow: auto;
}
.plan-popup-content {
  width: 100%;
}
.plan-popup-title {
  width: 100%;
  font-size: $largeFont;
  margin: 0 0 0;
  text-align: center;
  @include smallScreen() {
    text-align: left;
  }
}
.plan-popup-plan-name {
  color: $main;
  text-transform: uppercase;
}
.plan-popup-desc {
  font-size: $smallFont;
  text-align: center;
  @include smallScreen() {
    text-align: left;
  }
  margin: 0;
}
.plan-popup-break {
  width: 35%;
  max-width: 80px;
  height: 1px;
  background: $main;
  margin: 3rem auto;
  @include smallScreen() {
    margin: 3rem 0;
  }
}
.plan-popup-features {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  //max-width:640px;
}
.plan-popup-feature {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 25%;
}
.plan-popup-feature-icon {
  max-width: 40px;
  margin-bottom: 10px;
}
.plan-popup-feature-desc {
  font-size: $normalFont;
  margin: 0;
}
@include smallScreen() {
  .plan-popup-feature {
    width: 50%;
    margin: 1rem 0;
  }
}
