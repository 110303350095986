@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.checkout-layout {
  position: relative;
  width: 90%;
  margin: 3rem auto;
  max-width: 1200px;
  font-family: $fontPrimary;
  font-size: $normalFont;
  input,
  textarea,
  select,
  button {
    font-family: $fontPrimary;
  }
  * {
    box-sizing: border-box;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
.checkout-infobox-steps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.checkout-infobox {
  position: relative;
  width: 100%;
  margin-top: 5rem;
  @include mobileView() {
    margin-top: 4rem;
  }
}
.checkout-infobox-head {
  position: relative;
  width: 100%;
  // background:$main;
  padding: 0 0.2rem;
  margin-bottom: 3rem;
  // color:#FFF;
  text-align: center;
  color: $primary-text;
  @include mobileView() {
    margin-bottom: 2rem;
  }
}
.checkout-infobox-content {
  border: 1px solid rgba($lightgray, 0.5);
  border-top: 3px solid $main;
}
.checkout-head-title {
  font-size: $largeFont;
  font-weight: bold;
  @include mobileView() {
    font-size: $largeFontMobile;
  }
}
$gutter: 3rem;
.checkout-cart {
  position: relative;
  //width:100%;
  display: flex;
  flex-wrap: wrap;
  .checkout-layout.mod-apple-pay & {
    opacity: 0.3;
    pointer-events: none;
  }
}
.checkout-cart-item {
  // display:flex;
  width: 33.3333%;
  padding: $gutter;
}
.checkout-cart-head {
  margin-bottom: 2.5rem;
}
.checkout-cart-infos {
  display: flex;
  .checkout-cart-info {
    margin-right: 5rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
.checkout-cart-info {
  margin-bottom: 2.5rem;
}
.checkout-title {
  font-size: $mediumFont;
  font-weight: bold;
  margin: 0;
  @include mobileView() {
    font-size: $mediumFontMobile;
  }
}
.checkout-subtitle {
  font-size: $normalFont;
  font-weight: bold;
  margin: 0 0 0.5rem;
  color: $gray100;
  @include mobileView() {
    font-size: $normalFontMobile;
  }
}
.checkout-desc {
  font-size: $normalFont;
  margin: 0;
  @include mobileView() {
    font-size: $normalFontMobile;
  }
}

.checkout-checkshows {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.5rem;
}
.checkout-checkshow {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  margin-right: 2rem;
}
.checkout-checkshow-check {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: $main;
  margin-right: 0.75rem;
  margin-top: -0.1em;
  font-size: $smallFont;
  //opacity:.6;
}
.checkout-checkshow-label {
  @extend .checkout-desc;
}
.checkout-cart-price {
  @extend .checkout-title;
}
.checkout-control {
  width: 100%;
  padding: 3rem 0;
}
.checkout-btn {
  display: block;
  width: 100%;
  max-width: 640px;
  padding: 2rem;
  background: $main;
  color: #fff;
  margin: 0 auto;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  &:hover {
    background: $lightgreen;
  }
  font-size: $largeFont;
  @include mobileView() {
    padding: 1.5rem;
    font-size: $mediumFontMobile;
  }
}
.checkout-form {
  margin-top: 3rem;
  padding: 2rem 3rem;
  .checkout-layout.mod-apple-pay & {
    opacity: 0.3;
    pointer-events: none;
  }
}

.checkout-form-label {
  @extend .checkout-subtitle;
}
.checkout-form-input {
  width: 100%;
  border: 1px solid $lightgray;
  height: 38px;
  padding: 0 1rem;
  @extend .checkout-desc;
  &.is-err {
    border-color: red;
  }
}
.checkout-form-textarea {
  width: 100%;
  border: 1px solid $lightgray;
  height: 12rem;
  resize: none;
  padding: 1rem;
  @extend .checkout-desc;
  &.is-err {
    border-color: red;
  }
}
.checkout-form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 #{-$gutter/2} 2rem;
}
.checkout-form-col {
  width: 50%;
  padding: 0 $gutter/2;
  &.mod-full,
  &:first-child:last-child {
    width: 100%;
  }
  &.mod-full {
    margin-top: 2rem;
    &:first-child {
      margin-top: 0;
    }
  }
}
.checkout-form-remark {
  margin-top: 1rem;
  font-size: $smallFont;
  color: $lightgray;
  @include mobileView() {
    font-size: $smallFontMobile;
  }
}
.checkout-form-delivery-msg {
  margin-top: 1rem;
  font-size: $smallFont;
  color: #000;
  @include mobileView() {
    font-size: $smallFontMobile;
  }
}
.checkout-form-delivery-errmsg {
  margin-top: 1rem;
  font-size: $smallFont;
  color: red;
}
.checkout-form-errmsg {
  @extend .checkout-form-remark;
  color: red;
}

.checkout-form-select {
  > div {
    border: 1px solid $lightgray;
    border-radius: 0;
    font-size: $normalFont;
    @include mobileView() {
      font-size: $normalFontMobile;
    }
  }
  [class*='-indicatorSeparator'] {
    display: none;
  }
}

.checkout-payment {
  display: flex;
  //align-items: center;
  flex-flow: column;
  flex-wrap: wrap;
  align-items: center;
  &.is-hidden {
    .checkout-infobox,
    .checkout-control {
      display: none;
    }
  }
}
.checkout-payment-form,
.checkout-payment-summary {
  width: 100%;
  position: relative;
  padding: 4rem 0 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: $normalFont;
  @include mobileView() {
    font-size: $normalFontMobile;
  }
}
.checkout-payment-summary {
  max-width: 360px;
  .checkout-layout.mod-apple-pay & {
    opacity: 0.3;
    pointer-events: none;
  }
}
.checkout-payment-form {
  padding-bottom: 3rem;
}
.checkout-cc {
  width: 100%;
  padding: 0 3rem;
  &.is-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &.is-hidden {
    display: none !important;
  }
}
// .checkout-payment-summary{
//     width:25%;
//     padding-right:2rem;
//     margin-right:2rem;
//     &:after{
//         // content:'';
//         // display:block;
//         // width:1px;
//         // height:60%;
//         // background:$lightgray;
//         // position:absolute;
//         // right:0;
//         // top:20%;
//     }
// }
// .checkout-payment-form{
//     width:50%;
// }
.checkout-payment-subtotal,
.checkout-payment-extra,
.checkout-payment-total {
  padding: 1rem 0;
}
.checkout-cc-icons {
  margin: 0 0 2rem;
}
.checkout-cc-icon {
  max-width: 100%;
}
.checkout-cc-num,
.checkout-cc-expiry,
.checkout-cc-cvc {
  padding: 1rem 1rem;
  border: 1px solid $gray100;
  > * {
  }
}
.checkout-payment-subtotal {
  color: $gray100;
}
.checkout-payment-extra {
  color: $gray100;
}
.checkout-payment-total {
  font-size: $largeFont;
}
.checkout-payment-label {
  color: $gray100;
  padding-right: 1rem;
}
.checkout-payment-label-fragment {
  color: $gray100;
  display: inline-block;
}
.checkout-payment-figure {
  //margin-left:1rem;
  flex: 0 0 auto;
  width: 90px;
  color: #000;
}
//radio
.checkout-layout {
  .radio-pickers {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .radio-pickers-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .radio-pickers-breaker {
    width: 100%;
  }
  .radio-picker {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    input[type='radio'] {
      display: none;
    }
    input[type='radio']:checked {
      & + .radio-picker-label {
        &:after {
          opacity: 1;
        }
      }
    }
    &.is-disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  .radio-picker-txt {
    display: inline-block;
    width: 300px;
    font-size: $smallFont;
    font-weight: bold;

    .week-picker & {
      color: $lightgray;
    }
    .week-picker input[type='radio']:checked + .radio-picker-label & {
      color: $main;
    }
  }

  .radio-picker-label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      $size: 15px;
      width: $size;
      height: $size;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -$size/2 - 2px;
      border: 1px solid $main;
      border-radius: 50%;
      transform-origin: center center;
    }
    &:before {
      border: 1px solid $lightgray;
    }
    &:after {
      border: 1px solid $main;
      background: $main;
      transform: scale(0.5);
      opacity: 0;
    }
  }
}
.checkout-cc-desc {
  margin-bottom: 2rem;
}
@include largeScreen() {
  .checkout-payment-summary {
    width: 30%;
  }
}
@include mediumScreen() {
  .checkout-cart-item {
    width: 50%;
  }
  .checkout-payment-summary {
    width: 40%;
  }
  // .checkout-payment-summary{
  //     width:100%;
  // }
}
@include smallScreen() {
  .checkout-cart-item {
    width: 100%;
  }
  .checkout-form-col {
    width: 100%;
    margin: 0 0 2rem;
    &:last-child {
      margin: 0;
    }
    &.mod-full {
      margin-top: 0;
    }
  }
  .checkout-payment-form,
  .checkout-payment-summary {
    padding: 2rem 0;
    align-items: center;
  }
  .checkout-payment-form {
    width: 100%;
  }
  .checkout-payment-summary {
    width: 90%;
    align-items: center;
  }
}

.checkout-layout-loading {
  &.is-hidden {
    display: none;
  }
}
.checkout-payment-credits {
  //text-align: right;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  //align-items: flex-end;

  @include smallScreen() {
    align-items: center;
  }
}
.checkout-payment-credits-field {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: space-between;
}
.checkout-payment-credits-input {
  width: 90px;
  flex: 0 0 auto;
  text-align: center;
  .checkout-form-input {
    text-align: center;
  }
}
.checkout-payment-credits-label {
  margin-right: 1rem;
  .checkout-payment-credits-msg {
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: $gray100;
  }
}
.checkout-payment-credits-warning {
  color: red;
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: $smallFont;
}
.checkout-payment-credits-msg {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: $smallFont;
}
.checkout-cart-remove {
  margin-left: 2rem;
  margin-top: -0.5rem;
}
.checkout-cart-addon-btn,
.checkout-cart-remove-btn {
  display: flex;
  align-items: center;
  background: $lightgray;
  padding: 0.5rem 2rem;
  font-size: $smallFont;
  &:hover {
    opacity: 0.75;
  }
  @include mobileView() {
    font-size: $smallFontMobile;
    padding: 0.25rem 1.25rem;
  }
}
.checkout-cart-addon-btn {
  width: 100%;
  background: $main;
  color: #fff;
  text-align: center;
  justify-content: center;
}
.checkout-cart-addon-btn-label,
.checkout-cart-remove-label {
  margin: 0;
  margin-top: 0.5rem;
  font-size: 1.3rem;
  @include mobileView() {
    font-size: $smallFontMobile;
  }
}
.checkout-cart-addon-btn-label {
  text-align: center;
  color: #fff;
}
.checkout-cart-remove-icon {
  margin-right: 0.75rem;
  font-size: 1.5rem;
}
.checkout-cart-sum {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout-free-snacks {
  width: 100%;
  color: $main;
  font-size: $normalFont;
  margin-top: 1rem;
  @include mobileView() {
    font-size: $normalFontMobile;
  }
}

.checkout-extras {
  position: relative;
  font-size: $normalFont;
  @include mobileView() {
    font-size: $normalFontMobile;
  }
}
.checkout-extra {
  position: relative;
  display: inline-block;
  margin-right: 0.7rem;
  &:last-child {
    .checkout-extra-comma {
      display: none;
    }
  }
}
.checkout-payment-subtotal,
.checkout-payment-extra {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkout-payment-break {
  display: block;
  width: 100px;
  height: 1px;
  margin: 1rem 0 2rem;
  background: $lightgray;
}
.checkout-form-radio .radio-picker {
  justify-content: flex-start;
}
@include smallScreen() {
  // .checkout-addrrow-building{
  //     order:1;
  // }
  // .checkout-addrrow-district{
  //     order:3;
  // }
  // .checkout-addrrow-addr{
  //     order:2;
  //     margin-bottom: 2rem;
  //     &:last-child{
  //         margin-bottom: 2rem;
  //     }
  // }
}
@include extraSmallScreen() {
  .checkout-layout .radio-picker-txt {
    width: 240px;
  }
}

.apple-pay-modal {
  width: 100%;
  margin-top: 1rem;
}
.apple-pay-holder {
  width: 100%;
}
.apple-pay-body {
  width: 100%;
}
.apple-pay-desc {
  text-align: center;
  margin-bottom: 1rem;
}
.apple-pay-btn {
  width: 100%;
  max-width: 360px;
  margin: 1rem auto;
}

.feeding-hk-roundup,
.reminder-email {
  // width:100%;
  margin: 0 auto;
}
.reminder-email {
  margin-top: 1rem;
  font-size: $normalFont;
  @include mobileView() {
    font-size: $normalFontMobile;
  }
}

.feeding-hk-roundup-input,
.reminder-email-input {
  display: none;
  &:checked + .feeding-hk-roundup-label,
  &:checked + .reminder-email-label {
    .feeding-hk-roundup-bar,
    .reminder-email-bar {
      justify-content: flex-end;
      background: $main;
    }
    .feeding-hk-roundup-bar-pt,
    .reminder-email-bar-pt {
      &:before {
        display: none;
      }
      &:after {
        width: 6px;
        height: 50%;
        left: 40%;
        top: 15%;
        margin-left: -3px;
        background: transparent;
        border-bottom: 3px solid $main;
        border-right: 3px solid $main;
      }
    }
  }
}
.feeding-hk-roundup-label,
.reminder-email-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.feeding-hk-roundup-bar,
.reminder-email-bar {
  display: block;
  width: 60px;
  height: 30px;
  border-radius: 40px;
  background: #ccc;
  margin-right: 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 3px;
  transition: 0.3s;
}
.feeding-hk-roundup-bar {
  margin-left: 10px;
  margin-right: 0;
}
.feeding-hk-roundup-bar-pt,
.reminder-email-bar-pt {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #fff;
  transition: 0.3s;
  transform: rotate(45deg);
  &:before {
    content: '';
    display: block;
    width: 70%;
    height: 3px;
    background: #ccc;
    position: absolute;
    top: 50%;
    margin-top: -1.5px;
    left: 15%;
  }
  &:after {
    content: '';
    display: block;
    height: 70%;
    width: 3px;
    background: #ccc;
    position: absolute;
    left: 50%;
    margin-left: -1.5px;
    top: 15%;
  }
}
.feeding-hk {
  position: relative;
  width: 100%;
  max-width: 480px;
  margin: 0 auto 1rem;
}
.feeding-hk-intro {
  padding: 1rem 0 2rem;
  display: flex;
  justify-content: space-between;
}
.feeding-hk-intro-pic {
  width: 64px;
  margin-right: 1rem;
  flex: 0 0 auto;
  font-size: 0;
  img {
    width: 100%;
  }
}
.feeding-hk-title {
  font-size: $smallFont;
  font-weight: normal;
  margin: 0 0 5px;
}
.feeding-hk-desc {
  font-size: $smallFont;
  color: $gray100;
  margin: 0;
}
.feeding-hk-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  color: $gray100;
}
.feeding-hk-more {
  display: flex;
  width: 90px;
  border: 1px solid #c0c0c0;
  justify-content: space-between;
  button {
    font-size: 1.6rem;
    padding: 0 0.5rem;
  }
}
.feeding-hk-more-control {
  width: 100%;
  display: flex;
  align-items: center;
  color: #000;
  margin-top: 0.125rem;
}
.feeding-hk-more-input {
  width: 100%;
  text-align: center;
  height: 38px;
}
.feeding-hk-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.checkout-money-back {
  padding: 6rem 3rem 0;
  text-align: center;
  @include mobileView() {
    padding: 4rem 3rem 0;
  }
}
.checkout-money-back-title {
  font-weight: normal;
  font-size: $mediumFont;
  margin: 0 0 2rem;
  @include mobileView() {
    font-size: $normalFontMobile;
    margin: 0 0 1rem;
  }
}
.checkout-money-back-desc {
  font-size: $normalFont;
  margin: 0;
  @include mobileView() {
    font-size: $normalFontMobile;
  }
}

.checkout-cart-addon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout-cart-addon-table {
  margin-bottom: 2rem;
  th,
  td {
    padding-right: 1rem;
    text-align: left;
  }
  td {
    padding-bottom: 1.5rem;
  }
}
.checkout-cart-addon-control {
  display: flex;
  button {
    min-width: 70px;
    margin-right: 1rem;
    width: auto;
    @media (max-width: 350px) {
      min-width: 50px;
      padding: 0.3rem 1rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
