@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.error-text {
  color: red !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: left;
}

.account-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 2rem;
  width: 65%;
  @media (max-width: 1280px) {
    width: 90%;
  }
  @media (max-width: 1023px) {
    width: 80%;
  }

  .signed-in-as-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border: $lightgray solid 0.1rem;

    .profile-icon {
      position: relative;
      width: 2.75rem;
      margin: 0 0.5rem;
      padding-right: 0.5rem;
      // border-right: $lightgray solid 0.1rem;
      background: url('/img/User.png') no-repeat center center;
      background-size: contain;
      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
      }
    }

    .profile-id-container {
      position: relative;
      display: flex;
      padding: 1rem 2rem;
      align-items: center;
      justify-content: space-between;
      &:before {
        content: '';
        position: absolute;
        display: block;
        $length: 75%;
        width: 1px;
        height: $length;
        // margin-top:-$length/2;
        left: 0;
        top: (100% - $length)/2;
        background: $lightgray;
      }

      p {
        margin: 0 1rem;
        color: $darkgray;
        font-family: 'Josefin Sans', sans-serif;
        font-size: $normalFont;
      }

      .bold-text {
        margin: 0 1rem;
        color: $darkgray;
        font-family: 'Josefin Sans', sans-serif;
        font-size: $normalFont;
      }
    }
  }
  .sharing-is-caring-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 3rem;
    margin: 2rem 0;
    border: $lightgray solid 0.1rem;

    .sharing-is-caring-right-container {
      margin-left: 2rem;
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: column;

      .sharing-is-caring-text {
        color: $darkgray;
        font-family:
          Josefin Sans,
          sans-serif;
        font-size: $smallFont;
        text-align: left;
      }

      .sharing-media-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .link-copy-button {
          cursor: pointer;
        }

        p {
          color: $darkgray;
          font-family:
            Josefin Sans,
            sans-serif;
          font-size: $mediumFont;
          margin: 1rem 0;
          text-align: left;
        }

        img {
          margin: 0 0 0 1rem;
        }
      }

      .referral-link-container {
        display: flex;
        flex-direction: column;
        width: 80%;
        align-items: center;
        border: $main solid 0.1rem;
        padding: 1rem;

        p {
          text-align: center;
          font-family:
            Josefin Sans,
            sans-serif;
          font-size: $smallFont;
        }

        .highlight {
          color: $main;
          font-size: $normalFont;
          margin-top: 1rem;
          margin-bottom: 0;
        }
      }

      .referral-link {
        color: $main;
        margin: 2rem 0;
        font-family:
          Josefin Sans,
          sans-serif;
        font-size: $smallFont;
      }
    }
  }

  .details-address-book-container {
    margin: 3rem 0;
    display: flex;
    flex: 1;
    justify-content: space-between;

    .details-container {
      display: flex;
      margin-bottom: 2rem;
      flex: 0.46;
      flex-direction: column;
      position: relative;

      .content-container {
        display: flex;
        margin-top: 1rem;
        flex: 1;
        padding: 4rem;
        min-height: 10rem;
        border: $lightgray solid 0.1rem;
        border-radius: 0.5rem;
        justify-content: center;
        flex-direction: column;

        p {
          color: $darkgray;
          font-size: $normalFont;
          margin: 0.5rem 0;
          font-family:
            Josefin Sans,
            sans-serif;

          input {
            border: $lightgray solid 0.1rem;
            margin-left: 1rem;
            padding: 0.25rem 0;
            text-align: center;
          }

          select {
            -webkit-appearance: menulist;
            -moz-appearance: menulist;
            border: $lightgray solid 0.1rem;
            margin-left: 1rem;
            padding: 0.25rem 0;
            text-align: center;
          }
        }
      }

      .details-buttons-container {
        display: flex;
        flex-direction: row;
        position: absolute;
        bottom: -0.5rem;
        right: 2rem;

        button {
          border: $lightgray solid 0.1rem;
          background-color: $white;
          border-radius: 0.5rem;
          padding: 0.5rem 2rem;
          margin-left: 2rem;
          font-size: $smallFont;
          font-family:
            Josefin Sans,
            sans-serif;
        }

        button:hover {
          background-color: rgb(116, 188, 176);
          color: $white;
        }
      }
    }

    .address-container {
      display: flex;
      flex: 0.46;
    }
  }

  .full-width-details-container {
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    position: relative;

    .balance-container {
      border: $lightgray solid 0.1rem;

      .available-store-credit {
        font-family:
          Josefin Sans,
          sans-serif;
        font-size: $smallFont;

        p {
          display: inline;
          color: $main;
          font-weight: bold;
        }
      }
    }

    .content-container {
      display: flex;
      border: $lightgray solid 0.1rem;
      align-items: center;
      flex-direction: column;
      margin: 1rem;
      padding: 2rem 0;

      &.balance-container {
        padding: 3rem 0;
        p {
          margin: 0;
        }
      }

      .cc-form {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .hidden-element {
        width: 0;
        height: 0;
        visibility: hidden;
      }

      .stripe-element-container {
        width: 100%;
        height: 2rem;
        margin: 1rem 0;
        padding: 0.5rem 0;
        border: $main solid 0.1rem;
      }

      p {
        margin-top: 2rem;
        margin-bottom: 0;
        font-size: $normalFont;
        font-family:
          Josefin Sans,
          sans-serif;
        text-align: left;
      }

      input {
        width: 100%;
        border: $lightgray solid 0.1rem;
        padding: 1rem 0;
        text-align: center;
      }
    }
    .full-width-details-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      right: 0;

      button {
        border: $lightgray solid 0.1rem;
        background-color: $white;
        border-radius: 0.5rem;
        padding: 0.5rem 2rem;
        margin-left: 2rem;
        font-size: $smallFont;
        font-family:
          Josefin Sans,
          sans-serif;
      }

      button:hover {
        background-color: rgb(116, 188, 176);
        color: $white;
      }
    }
    table {
      margin: 1rem 0;
      width: 100%;
      text-align: center;
      font-size: $smallFont;
      font-family:
        Josefin Sans,
        sans-serif;
    }

    button {
      align-self: flex-end;
      margin-top: 1rem;
      border: $lightgray solid 0.1rem;
      padding: 0.5rem 2rem;
      border-radius: 0.5rem;
      font-size: $smallFont;
      font-family:
        Josefin Sans,
        sans-serif;
    }

    button:hover {
      background-color: rgb(116, 188, 176);
      color: $white;
    }
  }
}

.header-container {
  display: flex;
  flex: 1;
  flex-direction: row;

  .header-bottom-border {
    flex: 1;
    border-bottom: $lightgray solid 0.1rem;
  }

  .header-text {
    margin-left: 2rem;
    text-align: right;
    align-self: flex-end;
    font-size: $smallFont;
    color: $darkgray;
  }
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

@include mobileView() {
  .account-container {
    width: 90%;
    .signed-in-as-container {
      .profile-icon {
        width: 5rem;
      }
      .profile-id-container {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .sharing-is-caring-container {
      flex-direction: column;
      padding: 3rem 0;

      img {
        margin-bottom: 2rem;
      }

      .sharing-is-caring-right-container {
        margin: 0;
        justify-content: flex-start;

        .referral-link-container {
          width: 90%;
        }
      }
    }

    .details-address-book-container {
      flex-direction: column;

      .margin-bottom {
        margin-bottom: 2rem;
      }
    }

    .full-width-details-container {
      table {
        margin: 1rem 0;
      }
    }
  }

  .available-store-credit {
    text-align: center;
  }
}
.acc-orders-id {
  width: 10%;
  @include smallScreen() {
    width: 20%;
  }
}
.acc-orders-date {
  width: 20%;
  @include smallScreen() {
    width: 25%;
  }
}
.acc-orders-amount {
  width: 20%;
  @include smallScreen() {
    width: 25%;
  }
}
.acc-orders {
  th,
  td {
    padding: 1rem 0.25rem;
    line-height: 1.3;
    vertical-align: top;
    text-align: center;
    // &.acc-orders-id,
    // &.acc-orders-date{
    //   text-align: center;
    // }
  }
}
.account-container .full-width-details-container table {
  @include smallScreen() {
    font-size: 1.2rem;
  }
}
.account-vip-tag {
  display: none;
  margin-left: 1rem;
  border: 1px solid $black;
  padding: 8px 8px 5px;
  color: $black;
  font-family: $fontPrimary;
  font-size: $smallFont;
  // font-weight:bold;
}
.account-user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.account-vip-logo {
  display: none;
  position: relative;
  width: 110px;
  background: url('/img/assets/vip/logo-vip-black-txt-transparent.png')
    no-repeat center center;
  background-size: contain;
  @include smallScreen() {
    width: 80px;
    margin-left: 0.5rem;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
}
.account-container.account-vip {
  .signed-in-as-container {
    background: url('/img/assets/vip/bg-vip-long.jpg') repeat-y center center;
    background-size: 100% auto;
    p {
      color: $black;
    }
    .profile-icon {
      @media (max-width: 1023px) {
        width: 8rem;
      }
      @include smallScreen() {
        width: 8rem;
      }
    }
  }
  .signed-in-as-container .profile-id-container:before {
    background: #fff;
  }
  .profile-id-container .bold-text {
    font-weight: bold;
    color: #fff;
  }
  .account-vip-tag {
    display: block;
  }
  .account-vip-logo {
    display: block;
  }
  .profile-icon {
    background-image: url('/img/assets/vip/icon-vip-heart.png');
  }
}
.account-container
  .full-width-details-container
  button.acc-orders-products-item,
.acc-orders-products-item {
  border: 0;
  appearance: none;
  padding: 0;
  &:hover {
    background: transparent;
    color: #000;
    text-decoration: underline;
  }
}

.item-detail-popup {
  font-size: 13px;
  line-height: 1.5;
  table {
    width: 100%;
    td {
      vertical-align: top;
      padding-bottom: 15px;
      &:first-child {
        text-align: left;
        white-space: nowrap;
        padding-right: 20px;
      }
      &:last-child {
        text-align: left;
      }
    }
  }
}
.acc-orders-products {
  white-space: pre;
}

.acc-orders-products .acc-orders-products-item:first-child {
  margin-top: 0;
}

.item-detail-align-left {
  text-align: left;
}

.item-detail-delivery-date {
  white-space: break-spaces;
}

.order-detail-popup {
  table {
    th {
      text-align: left;
    }
  }
}
