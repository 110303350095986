@import '../../styles/AppDesign';
@import '../../styles/media-queries';

.privacy-policy-container {
  font-family: 'Josefin Sans', sans-serif;
  color: #4a4a4a;
  width: 100%;

  > h1 {
    font-size: $largeFont + 2rem;
    color: white;
    text-align: center;
    padding: 40px;
    margin-top: 50px;
    background-color: $main;
    @include smallScreen() {
      font-size: $largeFont + 1rem;
    }
  }

  .private-conditions-content {
    font-size: 16px;
    width: 80%;
    padding: 36px 10%;
    text-align: left;
    .private-content-title {
      font-family: 'BebasNeue', sans-serif;
      font-size: 26px;
      padding-bottom: 20px;
      padding-top: 10px;
    }
    .private-content-desc {
      padding: 0px 30px;
      line-height: 24px;
      color: #000;
      text-align: justify;
    }
    .content-list {
      padding-left: 15px;
    }

    a:visited,
    a:link {
      color: #587d62;
      text-decoration: none;
    }
  }
}
