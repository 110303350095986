@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.addon-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: table;

  &.mod-invisible {
    display: none !important;
    pointer-events: none;
    z-index: -1;
  }
}

.addon-popup-body {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.addon-popup-body-inner {
  background: #fff;
  display: block;
  width: 90%;
  max-width: 520px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 2rem;
  box-sizing: border-box;
  @media (max-width: 560px) {
    max-width: 350px;
  }
  @media (max-width: 349px) {
    max-width: 320px;
  }
}

.addon-popup-content {
  display: none;

  &.mod-active {
    display: block;
  }
}

.addon-popup-scroller {
  max-height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.addon-popup-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.addon-popup-close {
  position: relative;
  $size: 20px;
  width: $size;
  height: $size;
  font-size: 0;
  padding: 0;
}

.addon-popup-close-graphic {
  position: relative;
  display: block;
  $size: 100%;
  width: $size;
  height: $size;
  transform: rotate(45deg);

  &:before,
  &:after {
    content: '';
    $height: 2px;
    position: absolute;
    display: block;
    width: 100%;
    height: $height;
    background: $main;
    top: 50%;
    margin-left: -$height/2;
  }

  &:before {
    transform: rotate(90deg);
  }
}

.addon-popup-back {
  padding: 0;
  color: $lightgray;
}

.addon-popup-title {
  text-align: center;
  margin: 0 0 2rem;
}

.addon-popup-item {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 2rem;
}

.addon-popup-item-img {
  position: relative;
  display: block;
  $size: 80px;
  width: $size;
  height: $size;
  background: no-repeat center center;
  background-size: 100%;
  border: $lightgray solid 1px;
  flex: 0 0 auto;
  margin-right: 1.5rem;
  @media (max-width: 480px) {
    $size: 60px;
    width: $size;
    height: $size;
  }
}

.addon-popup-item-title {
  display: inline-block;
  font-family: $fontSecondary;
  font-size: $normalFontMobile;
  margin: 0 0 0.5rem;
  margin-right: 1rem;
}

.addon-popup-item-price {
  display: inline-block;
  font-family: $fontSecondary;
  font-size: $normalFontMobile;
  color: $gray;
}

.addon-popup-item-desc {
  margin: 0 0 0.5rem;
}

.addon-popup-item-price {
  display: inline-block;
}

.addon-popup-btns {
  text-align: center;
  padding: 1rem;
  margin-top: 1rem;
}

.addon-popup-btn,
.addon-popup-item-btn {
  display: inline-block;
  font-size: $smallFontMobile;
  font-family: $fontPrimary;
  font-weight: bold;
  // font-family: $fontSecondary;
  // letter-spacing: 0.1rem;
  font-weight: lighter;
  color: $white;
  background-color: $main;
  padding: 0.65rem 1.5rem 0.5rem;
  border: none;
  margin-right: 3rem;
  white-space: nowrap;

  @include largeScreen() {
    font-size: $smallerFont;
    padding: 0.5rem 1rem;
  }

  @include mobileView() {
    font-size: $smallerFont;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
  }
}

.addon-popup-btn {
  font-size: $smallFontMobile;
  padding: 0.75rem 1.5rem;
  margin-left: 0;
  margin-right: 0;

  @include largeScreen() {
    font-size: $smallFontMobile;
  }

  @include mobileView() {
    font-size: $smallFontMobile;
  }

  &.mod-yellow {
    background-color: $yellow;
    color: $black;
  }
}

.addon-popup-checkbox {
  padding: 1rem 0;
  margin: 2rem auto 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addon-popup-checkbox-input {
  color: $main;
  border: 1px solid $lightgray;
}

.addon-popup-checkbox-label {
  margin-left: 0.5rem;
}

.addon-item-pickers {
  position: relative;

  &:before {
    content: '';
    $size: 1000vw;
    position: absolute;
    top: 0;
    left: -$size/2;
    width: $size;
    height: 1px;
    background: $lightgray;
  }
}

.addon-item-picker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  box-sizing: border-box;

  &:after {
    content: '';
    $size: 1000vw;
    position: absolute;
    bottom: 0;
    left: -$size/2;
    width: $size;
    height: 1px;
    background: $lightgray;
  }
}

.addon-item-picker-date-holder {
  font-weight: bold;
  line-height: 1.5em;
}

.addon-item-picker-control {
  border: 1px solid $lightgray;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addon-popup-slider {
  box-sizing: border-box;
  margin: 0 auto 3rem;
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0 60px;
  box-sizing: border-box;
  @media (max-width: 480px) {
    padding: 0 35px;
  }

  .addon-popup-item {
    margin: 0;
  }

  .addon-popup-item-title {
    display: block;
  }

  .swiper-control {
    @media (max-width: 480px) {
      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        left: 0;
      }
      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        right: 0;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      font-size: 0;
      background: transparent;
      color: #000;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 3rem;
      @media (max-width: 480px) {
        font-size: 2rem;
      }
    }
  }
}
