@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

section {
  &.our-suppliers {
    &-header {
      height: 150px;
      background-color: $main;

      .content {
        h1 {
          text-align: center;
          color: $white;
          font-family: 'Josefin Sans', sans-serif;
        }
      }
    }

    &-our-promise {
      height: auto;
      max-height: unset;
      .content {
        width: 80%;
        margin: 5rem auto;

        h2 {
          color: $main;
          font-size: 3.3rem;
          margin-bottom: 3rem;
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          span {
            font-size: inherit;
            color: inherit;
            margin: 0 30px;
          }

          hr {
            flex: 1;
            border: none;
            height: 1px;
            background: $lightgray;
          }
        }

        p {
          font-size: $mediumFont;
          align-self: flex-start;
          line-height: 1.8;
        }

        .features {
          margin-top: 3rem;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 90%;
          max-width: 1920px;

          .feature {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 19%;

            img {
              width: 80%;
            }

            p {
              font-family: 'BebasNeueRegular';
              font-weight: 100;
              font-size: 2.25rem;
              width: 100%;
              text-align: center;
            }
          }
        }
        .video {
          &-container {
            margin-top: 3rem;
            width: 75%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
              width: 75%;
              text-align: center;
              align-self: center;
            }
          }

          &-player {
            width: 560px;
            height: 315px;
          }
        }
      }
    }
  }
}

@include mobileView() {
  section {
    &.our-suppliers {
      &-our-promise {
        .content {
          width: 90%;
          h2 {
            width: 100%;
            white-space: nowrap;
            text-align: center;

            span {
              margin: 0 20px;
            }
          }

          p {
            font-size: $normalFont;
          }

          .features {
            flex-wrap: wrap;
            width: 105%;
            justify-content: center;

            .feature {
              width: 50%;
              max-width: 250px;
              margin-bottom: 3rem;

              img {
                width: 90%;
                max-width: 250px;
              }

              p {
                width: 90%;
                line-height: 1.5;
                margin: 15px auto;
              }
            }
          }

          .video {
            &-container {
              width: 100%;
              p {
                width: 100%;
              }
            }

            &-player {
              width: 95% !important;
            }
          }
        }
      }
    }
  }
}
