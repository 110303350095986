@import '../../styles/AppDesign.scss';

.faq-page {
  width: 100%;

  a:visited,
  a:link {
    color: #587d62;
    text-decoration: none;
  }
  .faq-header {
    margin-top: 5rem;
    padding: 0.75rem;
    margin-bottom: 2rem;
    background-color: $main;

    &-title {
      color: $white;
      text-align: center;
      font-size: $largeFont + 1rem;
      font-family:
        Josefin Sans,
        sans-serif;
    }
  }

  .faq-footer {
    .line2 {
      font-size: $normalFont;
      margin: 1rem 0;
    }

    .tele {
      color: $main;
    }

    p {
      font-family:
        Josefin Sans,
        sans-serif;
      color: $darkgray;
      font-size: $largeFont;
      text-align: center;
      margin: 0.5rem 0;
    }
  }
}
.faq-img {
  margin-top: 2rem;
  display: block;
  max-width: 100%;
}
