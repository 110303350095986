@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';
@import '../../styles/mixins.scss';

.steps-container {
  .step2-goal-title {
    margin-top: 4rem;
    @include mobileView() {
      margin: 2.5rem 0 1rem;
    }
  }
  .goal-container {
    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;

    .goal {
      display: block;
      width: 100%;
      margin: 1%;
      //max-width: 300px;
      margin: 0 auto;
      padding: 0;

      img {
        width: 100%;
      }
    }
  }
  .goal-thumb {
    width: 100%;
    // padding-bottom: 100%;
    padding-bottom: 35.9712%;
    @include image-loading-style;
  }
  .goal-thumb-src {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: no-repeat center center;
    background-size: cover;
  }
  .package-container {
    width: 90%;
    max-width: 1200px;
    padding: 0;
  }

  .btn-next {
    max-width: 320px;
    line-height: 1.125;
    padding: 1rem 12rem 0.5rem;
    background-color: $main;
    color: $white;
    font-size: $bigFont;
    font-family: $fontSecondary;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    background-image: url('/img/assets/white_arrow.png');
    background-size: 1.1rem;
    background-repeat: no-repeat;
    background-position: right 2.2rem center;
    box-sizing: border-box;
    text-align: center;
    white-space: nowrap;
    &.is-disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  &.lang-zh .btn-next {
    font-size: $largeFont + 0.4rem;
  }
}

.goal-container {
  @include m-swiper-slider();
  max-width: 1200px;
  overflow: hidden;
  position: relative;
  width: 90%;
  box-sizing: border-box;
  @include mobileView() {
    max-width: 640px;
  }
  @include smallScreen() {
    max-width: 320px;
  }
}
@include mobileView() {
  .steps-container {
    .btn-next {
      width: 90%;
      padding: 1rem 8rem 0.5rem;
    }
  }
}

.package-container-desc {
  font-size: $smallFont;
  font-style: italic;
  text-align: center;
}
.goal-static-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // margin-left:-15px;
  // margin-right:-15px;
  .goal-item {
    width: 33.3333%;
    padding: 15px 15px 0;
    box-sizing: border-box;
    @include mediumScreen() {
      width: 50%;
    }
    @include smallScreen() {
      width: 100%;
    }
  }
}
.goal-item {
  .plan-info-helper {
    text-align: left;
    @include mobileView() {
      padding: 0.5rem 0;
    }
  }
}
