@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';
.home-our-meals {
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;
  height: auto;
  display: block;
  // @include mobileView() {
  //   display:block;
  // }
}
.home-our-meals-inner {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 3rem 6rem;
  @include mobileView() {
    padding: 3rem 3rem;
  }
}
.home-our-meals-title {
  margin: 0 auto 2rem;
  font-family: $fontSecondary;
  color: $main;
  font-size: $bigFont;
  @include mobileView() {
    font-size: $mediumFont;
    font-weight: bold;
  }
}
.home-our-meals-slider {
  box-sizing: border-box;
  margin: 0 auto 3rem;
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0 60px;
  @include mobileView() {
    max-width: 768px;
    padding: 0;
  }
  @media (max-width: 480px) {
    width: 75%;
    margin: 0 0 3rem;
  }
  .swiper-container {
    @include mobileView() {
      overflow: visible;
    }
  }
  .swiper-wrapper {
    width: auto;
    // margin-left:-10px;
    // margin-right:-10px;
  }
  .swiper-control {
    @include mobileView() {
      display: none;
    }
    .swiper-button-prev,
    .swiper-button-next {
      font-size: 0;
      background: transparent;
      color: $main;
    }
  }
}
.home-our-meals-slider-item {
  box-sizing: border-box;
  // padding:0 10px;
  // &:last-child{
  //   padding-right:0;
  // }
}
.home-our-meals-slider-img {
  background-size: cover;
  &:before {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
}
.home-our-meals-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: $normalFont;
  font-family:
    Josefin Sans,
    sans-serif;
  font-weight: lighter;
  color: $white;
  background-color: $main;
  padding: 0;
  border: none;
  width: 16rem;
  height: 4rem;
  &:active,
  &:visited,
  &:focus,
  &:hover {
    font-weight: lighter;
  }
  @include mobileView() {
    height: 4rem;
    width: 12rem;
    font-weight: bold;
  }
}
