@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';
.home-meal-plans {
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;
  height: auto;
  display: block;
  // @include mobileView() {
  //   display:block;
  // }
}
.home-meal-plans-inner {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 3rem 3rem;
  @include mobileView() {
    padding: 3rem 3rem;
  }
}
.home-meal-plans-title {
  margin: 0 auto 2rem;
  font-family: $fontSecondary;
  color: $main;
  font-size: $bigFont;
  @include mobileView() {
    font-size: $mediumFont;
    font-weight: bold;
  }
}
.home-meal-plans-slider {
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0 60px;
  @include mobileView() {
    max-width: 768px;
    padding: 0;
  }
  @include smallerScreen() {
    max-width: 270px;
  }
  .swiper-container {
    @include mobileView() {
      overflow: visible;
    }
  }
  .swiper-wrapper {
    width: auto;
  }
  .swiper-control {
    @include mobileView() {
      display: none;
    }
    .swiper-button-prev,
    .swiper-button-next {
      font-size: 0;
      background: transparent;
      color: $main;
    }
  }
}
.home-meal-plans-slider-item {
  box-sizing: border-box;
  // padding:0 10px;
  // &:last-child{
  //   padding-right:0;
  // }
}
.home-meal-plans-slider-img {
  width: 100%;
  background-size: cover;
  &:before {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 35.9712%;
  }
}

.home-meal-plans-info-helper {
  text-align: center;
  width: 100%;
  padding: 1.5rem 0;
}
section .home-meal-plans-info-helper-link,
.home-meal-plans-info-helper-link {
  text-align: right;
  color: $main;
  appearance: none;
  padding: 0;
  width: auto;
  height: auto;
  background: transparent;
  margin: 0 0.5rem;
  font-size: $smallFontMobile;
  //text-transform: uppercase;
}

.home-meal-plans-btn {
  font-size: $normalFont;
  font-family:
    Josefin Sans,
    sans-serif;
  font-weight: lighter;
  color: $white;
  background-color: $main;
  padding: 0;
  border: none;
  width: 16rem;
  height: 4rem;
  @include mobileView() {
    height: 3rem;
  }
}
