@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';

.promo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-family: 'GenJyuuGothic-Bold';

  .promo-img {
    object-fit: cover;
    flex: 1 1 0;
    max-width: 100%;
    min-width: 450px;
  }

  .promo-left {
    text-align: center;
    padding: 20px 3rem;
    justify-self: center;
    color: #fff;
    min-width: 500px;
    background-size: cover;

    .logo-img {
      margin-bottom: 20px;

      @media (max-width: 500px) {
        max-width: 250px;
      }
    }

    .promo-h1 {
      font-size: 95px;
      margin: 0 auto;
      text-shadow: 3px 3px 6px rgba(107, 0, 0, 0.5);

      @media (max-width: 500px) {
        font-size: 80px;
      }
    }

    .promo-h2 {
      font-size: 38px;
      margin: 0 auto;
      text-shadow: 2px 2px 4px rgba(128, 0, 0, 0.5);

      @media (max-width: 500px) {
        font-size: 32px;
      }
    }

    .h2-2 {
      text-shadow: none;
    }

    .promo-input {
      display: block;
      border-radius: 30px;
      border: 2px solid #e2e2e2;
      box-sizing: border-box;
      font-size: 20px;
      font-family:
        Josefin Sans,
        sans-serif;
      width: 85%;
      max-width: 95vw;
      margin: 1rem auto;
      padding: 12.5px 15px;
      height: 45px;
      text-align: center;
      background-color: #fff;

      &::placeholder {
        color: #d9d9d9;
        font-weight: 900;
      }
    }

    .code-section {
      position: relative;
      display: inline-block;

      .promo-code {
        border-radius: 20px;
        box-sizing: border-box;
        font-size: 25px;
        font-family:
          Josefin Sans,
          sans-serif;
        max-width: 85vw;
        margin-top: 3rem;
        height: 150px;
        text-align: center;
        background-color: #fff;
        border: 2px solid #ffffff;
        box-shadow:
          inset 0 0 3px rgba(128, 0, 0, 0.842),
          0 0 3px rgba(227, 224, 224, 0.895);
        padding: 10px 50px;

        @media (max-width: 500px) {
          height: 130px;
        }
      }

      .copy {
        position: absolute;
        top: 50%;
        right: 5%;
      }
    }

    .promo-btn {
      border-radius: 30px;
      padding: 11px 45px;
      background-color: #5d8066;
      color: #ffffff;
      font-size: 30px;
      font-weight: bold;

      @media (max-width: 500px) {
        font-size: 25px;
        padding: 10px 38px;
      }
    }

    .promo-desc {
      color: #fff;
      font-weight: 900;
      font-size: 13px;
      text-shadow: 0.5px 0.5px 1px rgba(49, 1, 1, 0.747);
    }

    .signup-desc {
      max-width: 97vw;
      display: inline-block;
    }
  }

  .left-1 {
    background-image: url('/img/promo-2.png');
  }

  .left-2 {
    background-image: url('/img/promo-4.png');
    background-position: center;
  }
}
