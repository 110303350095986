/*styling of form */
@import '../../styles/AppDesign.scss';

.Modal {
  background: #ffffff;
  position: fixed;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
}
.ReactModal__Overlay {
  z-index: 1000;
}

.login-page {
  width: 360px;
  margin: auto;

  .error {
    color: red;
    margin: 0;
    padding: 0;
    text-align: right;
  }

  .form {
    position: relative;
    z-index: 1;
    background: #ffffff;
    max-width: 360px;
    padding: 60px 45px 35px;
    text-align: center;
    box-shadow:
      0 0 20px 0 rgba(0, 0, 0, 0.2),
      0 5px 5px 0 rgba(0, 0, 0, 0.24);
    max-height: 70vh;
    overflow: auto;
    .fb-login-btn-wrap {
      width: 100%;
      padding: 0 15px;
      background: #3b5998;
      border-radius: 8px;
    }
    .facebook-login-btn {
      display: flex;
      justify-content: center;
      height: 40px;
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      //padding: 0 4rem;
      border-radius: 1rem;
      align-items: center;
      color: white;
      font-family:
        Josefin Sans,
        sans-serif;
      font-size: 16px;
      line-height: 1;
    }

    .checkbox-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
    }

    .login-form {
      label {
        display: block;
        text-align: left;
        margin: 1rem 0;
        font-weight: 500;
        font-size: $smallFont;
        font-family:
          Josefin Sans,
          sans-serif;

        input {
          border-radius: 8px;
          background: #fff;
          outline: none;
          border-style: none;
          border: 1px solid #e2e2e2;
          box-sizing: border-box;
          font-size: 16px;
          font-family:
            Josefin Sans,
            sans-serif;
          outline: 0;
          width: 100%;
          margin: 0.5rem 0;
          padding: 12.5px 15px;
          height: 45px;
          &::placeholder {
            color: $gray100;
          }
        }
      }
      .popup-button {
        margin: 2rem 0 1rem;
        width: 100%;
        padding: 12px;
        font-weight: 400;
        border-radius: 8px;
        font-family:
          Josefin Sans,
          sans-serif;
        background-color: #587d62;
        color: #fff;
        font-size: 16px;
        outline: none;
        cursor: pointer;
        border-color: #fff;
        border-width: 0 1px;
        border-style: solid;
        height: 45px;

        &:hover,
        &:active,
        &:focus {
          background: #78b9b0;
        }
      }

      .forgot-password {
        display: flex;
        align-items: flex-start;
      }

      .message {
        margin: 10px 0;
        color: $gray100;
        font-size: 12px;

        a {
          color: #4caf50;
          text-decoration: none;
        }
      }

      .radio-buttons {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;
      }
    }
  }
}
.login-form-hidden-field {
  display: none !important;
}

.clickable-text {
  text-align: left !important;
  font-size: $smallFont;
  color: $main;
  font-family:
    Josefin Sans,
    sans-serif;
  font-weight: 500;

  &.left {
    align-self: flex-start;
    display: flex;
  }
  &.margin {
    margin: 1rem;
  }

  &-center {
    text-align: center;
    margin: 0;
    font-size: $smallFont;
    color: $main;
    font-family:
      Josefin Sans,
      sans-serif;
    font-weight: 500;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.login-form-or {
  margin: 2rem 0 1rem;
  text-align: center;
  font-family:
    Josefin Sans,
    sans-serif;
  font-size: 14px;
  color: $gray100;
}

.login-form-label {
  display: none !important;
}
#newsletter-subscribe-checkbox {
  margin-top: -0.2rem;
  margin-right: 1rem;
}
.login-form-forgot-pw {
  margin-top: 2rem;
}

.order-detail-popup .form {
  position: relative;
  max-width: 460px;
}

.close-button {
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  top: 5%;
  right: 5%;
}
