@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

header {
  position: relative;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 15%;
  color: $primary-text;
  font-size: $smallFont;
  justify-content: space-evenly;
  border-bottom: 1px solid #e2e2e2;
  z-index: 100;

  .navigation {
    &-mobile {
      display: none;
    }

    width: 62%;
    display: flex;
    flex-direction: row;
    align-items: center;
    nav {
      display: flex;
      justify-content: space-between;
      padding: 0 3rem;
      width: 60%;
      @media (max-width: 1480px) {
        width: 65%;
      }
      @media (max-width: 1180px) {
        width: 62%;
      }
      @media (max-width: 1080px) {
        width: 60%;
      }
      a {
        color: $primary-text;
        @media (max-width: 1180px) {
          font-size: $smallFontMobile;
        }
      }
    }

    .logo {
      width: 8%;

      @media (min-width: 1024px) {
        min-width: 70px;
      }
      img {
        max-width: 100%;
      }
    }
  }

  .options {
    display: flex;
    justify-content: flex-end;
    width: 28%;

    .lang-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        margin: 0;
      }
      button {
        @media (max-width: 1180px) {
          font-size: $smallFontMobile;
        }
        p {
          margin: 0;
          font-size: $smallFont;
          @media (max-width: 1180px) {
            font-size: $smallFontMobile;
          }
        }
        b {
          margin: 0;
          font-size: $smallFont;
          @media (max-width: 1180px) {
            font-size: $smallFontMobile;
          }
        }
      }
    }

    & > * {
      margin-left: 1rem;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }

    .login {
      font-size: 1.3rem;
      cursor: pointer;
      align-self: center;
      margin: 0;

      b {
        color: $main;
        margin: 0;
      }

      b:hover {
        text-decoration: underline;
      }
    }

    .logged-in-user {
      display: flex;
      flex-direction: column;
      justify-content: center;

      button {
        padding: 0;
        align-self: flex-end;
        background-color: transparent;
        border: none;
        cursor: pointer;

        p {
          font-size: 1.1rem;
          text-decoration: underline;
          margin: 0;
        }
      }
    }
  }

  .bm {
    &-overlay {
      display: none;
    }

    &-menu-wrap {
      display: none;
      .login,
      .logged-in-user {
        color: #fff;
      }
      .logged-in-user {
        > button {
          padding: 0;
          color: #fff;
          p {
            margin: 0;
            color: #fff;
          }
        }
      }
    }
  }

  .cart-dropdown {
    display: block;
    position: absolute;
    width: 580px;
    z-index: 200;
    margin-top: -1px;
    right: 1rem;
    top: 6rem;
    background: $white;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
    text-align: center;

    > p {
      font-family:
        Josefin Sans,
        sans-serif;
      text-align: left;
      border-bottom: 0.1rem solid $lightgray;
      padding: 0 1rem;

      &.description {
        font-size: $mediumFont;
        border-bottom: none;
      }
    }

    > div {
      background: #eee;
      display: flex;
      flex-direction: column;

      > p {
        font-family:
          Josefin Sans,
          sans-serif;
        font-weight: bold;
        text-align: right;
        margin-right: 1rem;
        padding: 0 1rem;
        color: black;
      }

      .checkout-button {
        background-color: $main;
        color: $white;
        font-family:
          Josefin Sans,
          sans-serif;
        font-size: $normalFont;
        max-width: 60%;
        padding: 2rem;
        align-self: flex-end;
        margin: 1rem;
        @include mobileView() {
          font-size: $normalFontMobile;
          padding: 1.25rem;
          margin-top: 0;
        }
        &.is-disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
  }

  .cart-link {
    display: block;
    width: 100%;
    padding: 1rem 0;
    font-family: $fontPrimary;
    color: $primary-text;
    font-size: $smallFont;
  }
}

@include mobileView() {
  header {
    padding: 0;
    height: 65px;
    margin-bottom: 30px;
    .cart-btn {
      position: fixed;
      right: 0;
      top: 0;
      width: 99vw;
      z-index: 1200;
      max-width: 580px;
    }
    .cart-dropdown {
      display: flex !important;
      flex-direction: column;
      // width: 99vw;
      width: 100%;
      margin: 0;
      right: 0;
      align-self: center;
      text-align: center;
      box-sizing: border-box;
    }

    .navigation,
    .options {
      display: none;
    }

    .navigation-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //padding: 0 2.5rem;
      padding: 0 1.5rem;
      width: 100%;

      .itemsInCart {
        display: flex;
        flex-direction: row;
        align-items: center;

        > p {
          background: red;
          color: white;
          border-radius: 2.5rem;
          width: 2.5rem;
          height: 2.5rem;
          margin-right: 0.5rem;
          text-align: center;
        }
      }

      .burger-menu {
        background-image: url('/img/assets/menu.png');
        background-position: center;
        //background-size: 27px 32px;
        background-size: 15px 18px;
        background-repeat: no-repeat;
        background-color: $white;
        border: none;
        width: 27px;
        height: 32px;
      }

      .cart {
        background-image: url('/img/assets/cart.png');
        background-position: center;
        //background-size: 27px 32px;
        background-size: 18px auto;
        background-repeat: no-repeat;
        background-color: $white;
        border: none;
        width: 27px;
        height: 32px;
      }

      img {
        height: 45px;
      }
    }

    .bm {
      &-overlay {
        display: block;
      }

      &-menu-wrap {
        display: block;
      }
    }
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 80% !important;
  max-width: 300px;
  top: 0;
  left: 0;
}

/* General sidebar styles */
.bm-menu {
  background: $main;
  opacity: 1;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: $white;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: $white;
  font-size: 1.75rem;
  line-height: 3;
}
.login.bm-item {
  margin-bottom: 0;
}
.mobile-order-now,
.mobile-order-now.bm-item {
  font-size: 1.75rem;
  font-weight: normal;
  padding: 0.35rem 1rem;
  margin: 2rem 0 2rem;
  border: 1px solid #fff;
  display: inline-block !important;
  width: auto;
  line-height: 1.5;
  font-weight: bold;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

.cart-btn {
  position: relative;
  .options & {
    display: flex;
    align-items: center;
  }
  &.show-cart {
    .cart-dropdown {
      display: block;
    }
  }
}
.cart-trigger {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.5;
  margin: 1em 0;
  cursor: pointer;

  p {
    margin: 0 0 0 0.75rem;
  }
}

.navigation-mobile .logo {
  @include mobileView() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.navigation-mobile-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btn-order-now-holder {
  display: flex;
  align-items: center;
  margin-left: 0;
  margin-right: 2rem;
}
.btn-order-now {
  display: inline-block;
  font-size: $smallFont;
  font-family:
    Josefin Sans,
    sans-serif;
  font-weight: lighter;
  color: $white;
  background-color: $main;
  padding: 1rem 1.5rem 0.8rem;
  border: none;
  margin-left: 0;
  margin-right: 0;
  white-space: nowrap;
  @include largeScreen() {
    font-size: $smallFontMobile;
    padding: 1rem 1.5rem 0.8rem;
  }
  @include mobileView() {
    font-size: $smallerFont;
    margin-left: 0;
    margin-right: 0;
    padding: 0.5rem 1rem;
  }
}
header .options > {
  .btn-order-now-holder {
    margin-left: 0;
    margin-right: 2rem;
    @media (max-width: 1180px) {
      margin-right: 1rem;
    }
  }
}
.mobile-lang {
  .lang-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      margin: 0;
      color: #fff;
    }
    .mob-lang-seperator {
      margin: 0 1rem;
    }
    button {
      font-family: 'Montserrat-Light';
      font-weight: normal;
      &:first-child {
        padding-left: 0;
      }
      p {
        margin: 0;
        font-size: $smallFont;
        color: #fff;
      }
      b {
        margin: 0;
        font-size: $smallFont;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}

.logged-in-user-vip-logo {
  position: relative;
  width: 75px;
  height: 50px;
  background: url('/img/assets/vip/logo-vip-black-txt-transparent.png')
    no-repeat center center;
  background-size: cover;
  margin-right: 1rem;
  display: none;
  flex: 0 0 auto;
  @include smallScreen() {
    width: 60px;
    height: 45px;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
  .logged-in-user.mod-vip & {
    display: block;
  }
  .bm-menu-wrap & {
    background-image: url('/img/assets/vip/logo-vip-white-txt-black-heart.png');
  }
}

.logged-in-user-name {
  display: flex;
  align-items: center;
  font-size: $smallFont;
  @media (max-width: 1180px) {
    font-size: $smallFontMobile;
  }
}

.login-welcome-msg {
  white-space: nowrap;
  .bm-menu & {
    color: #fff;
  }
}

.gift-card-link {
  // padding-right:120px;
  position: relative;
  &:after {
    content: '';
    display: none;
    position: absolute;
    $h: 60px;
    top: 50%;
    right: 0;
    margin-top: -$h/2;
    width: 110px;
    height: $h;
    background: url('/img/assets/icon-flash-sales.png') no-repeat center center;
    background-size: contain;
  }
  .bm-menu & {
    display: none;
    // display:flex !important;
    flex-wrap: nowrap;
    align-items: center;
    padding-right: 0;
    &:after {
      padding-right: 0;
      background-color: #fff;
      background-size: 95% auto;
      $h: 25px;
      width: 85px;
      height: $h;
      position: relative;
      top: auto;
      right: auto;
      margin: 0;
      margin-left: 1rem;
    }
  }
}
