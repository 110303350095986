@import '../styles/AppDesign.scss';
@import '../styles/media-queries.scss';

html {
  font-size: 62.5%;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

footer,
header {
  max-width: 1920px;
}

main {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  outline: none;
}

p,
span {
  line-height: 1.5;
  color: $primary-text;
}

section {
  width: 100%;
  display: flex;
  height: 55vh;
  max-height: 450px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .content,
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1920px;
    margin: auto;
  }

  .section-title,
  h1 {
    font-size: $bigFont;
    font-family: 'BebasNeue';
    font-weight: normal;
    color: $main;
    margin: 0;

    @include mobileView {
      font-size: $bigFontMobile;
    }
  }

  h2 {
    margin: 0rem;
    font-size: $largeFont;
    font-family: 'Montserrat-Light';
    font-weight: normal;
    font-weight: 500;
    color: $gray;

    @include mobileView {
      font-size: $largeFontMobile;
    }
  }

  button {
    font-size: $normalFont;
    font-family:
      Josefin Sans,
      sans-serif;
    font-weight: lighter;
    color: $white;
    background-color: $main;
    padding: 0;
    border: none;
    width: 16rem;
    height: 4rem;
  }
}

.steps-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2% 0;

  .section-title,
  h1 {
    font-family: 'Josefin Sans';
    font-size: 3rem;
    font-weight: normal;
    text-align: center;
    line-height: 1.5;
    color: $darkgray;
    margin: 4rem 0;
    margin-bottom: 2rem;

    @include mobileView() {
      font-size: $largeFontMobile;
      font-weight: bold;
    }
  }
}

@include mobileView() {
  .steps-container {
    margin: 5% 0;
  }
}

.site-layout-notification {
  position: relative;
  width: 100%;

  &.mod-fixed {
    @include mobileView() {
      //margin-bottom: 65px;
    }
  }
}

.site-layout-header {
  position: relative;
  width: 100%;
  z-index: 999;

  &.mod-fixed {
    @include mobileView() {
      .navigation-mobile {
        position: fixed;
        top: 0;
        height: 65px;
        background: #fff;
        box-sizing: border-box;
        z-index: 10;
        border-bottom: 1px solid rgba($lightgray, 0.3);
      }
    }
  }
}

.a11y-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
