@mixin green-btn() {
  font-size: 1.6rem;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: lighter;
  color: #ffffff;
  background-color: $main;
  padding: 0;
  border: none;
  width: 16rem;
  height: 4rem;
}

@mixin m-swiper-slider() {
  position: relative;
  $offsetSpace: 60px;
  $offsetShadow: 5px;
  padding: 0 $offsetSpace;
  margin-bottom: 60px;
  &:before,
  &:after {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100vw;
    //pointer-events: none;
    background: #fff;
    z-index: 20;
  }
  &:before {
    left: 100%;
    margin-left: -($offsetSpace - $offsetShadow);
  }
  &:after {
    right: 100%;
    margin-right: -($offsetSpace - $offsetShadow);
  }
  .arrow {
    cursor: pointer;
  }
  .swiper-container {
    position: relative;
    overflow: visible;
    z-index: 10;
    &:not(.swiper-container-initialized) {
      .swiper-slide {
        &:first-child {
          margin-left: 30px;
          &:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    font-size: 0;
    pointer-events: all;
    &:before,
    &:after {
      color: $primary-text;
    }
    --swiper-navigation-size: 60px;
  }
  .swiper-pagination {
    //position:relative;
    top: 100%;
    width: 100%;
    left: 0;
    padding: 1rem 0;
    margin-bottom: 1rem;

    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active {
      $dotSize: 10px;
      $dotSpace: 6px;
      width: $dotSize;
      height: $dotSize;
      background: $lightgreen;
      margin: $dotSpace;
    }
  }
  .swiper-body {
    position: relative;
    width: calc(100% - 30px);
    margin: 0 auto;
  }
  .swiper-control {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 30;
    pointer-events: none;
  }

  @include mobileView() {
    padding: 0;
    &:before,
    &:after {
      display: none;
    }
    .swiper-control {
      display: none;
    }
  }
}

@mixin image-loading-style {
  background-color: $lightergray;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: url(#{$ImgloadingIcon}) no-repeat center center;
    background-size: 30px;
  }
}

@mixin a11y-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
