@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';
@import '../../styles/mixins.scss';

.notification-msg {
  background-color: $primary-text;
  color: #fff;
  width: 100%;
  text-align: center;
  padding: 1.5rem 0;
  font-family: $fontPrimary;
  font-size: $normalFont;
  &.is-hidden {
    display: none;
  }
  @include mobileView() {
    padding: 1rem 0;
  }
}
.notification-msg-wrapper {
  width: 92%;
  margin: 0 auto;
}

.notification-msg-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:first-child:last-child {
    margin: 0;
  }
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next,
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    position: relative;
    height: 2rem;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    margin: 0;
    color: #fff;
    &:after {
      font-size: $normalFont;
    }
  }
}
.notification-msg-slider-body {
  overflow: hidden;
  margin: 0 1rem;
  width: 100%;
}
.notification-msg-control {
  width: 50px;
  color: #fff;
}
.notification-msg-icon {
  color: #fff;
}
.notification-msg-prev,
.notification-msg-next {
}
.notification-msg-control-label {
  font-size: 0;
}
.notification-msg-msg {
  width: 100%;
  @include mobileView() {
    font-size: $smallFontMobile;
  }
}
