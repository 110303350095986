@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';

.q-a-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;

  .question {
    width: 70%;
    background-color: white;
    border: 1px solid $lightgray;
    color: $darkgray;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: none;
    font-family:
      Josefin Sans,
      sans-serif;
    font-size: $normalFont;
    text-align: center;
    box-sizing: border-box;
    @include smallScreen() {
      width: 90%;
    }

    &:hover {
      background-color: $main;
      color: white;
    }

    &:active {
      background-color: $main;
      color: white;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .answers {
    background-color: white;
    border: 1px solid $lightgray;
    // padding: 15px 0px;
    width: 70%;
    color: $darkgray;
    font-size: $smallFont;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    @include smallScreen() {
      width: 90%;
      padding: 10px;
    }
  }

  .display-linebreak {
    white-space: pre-line;
  }
}
