@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';

/*
	Billing Info Form
 */
.billing-info {
  padding: 5rem 3rem;
  background-color: #1e1e1e;
  box-shadow: -8px 8px 10px rgba(0, 0, 0, 0.7);

  * {
    box-sizing: border-box;
  }

  input,
  select {
    background: #fff;
  }

  margin: 3rem auto;
}

.billing-info-holder {
  max-width: 640px;
  .screen-msg {
    padding: 1rem 2rem;
    background: rgba(255, 255, 255, 0.6);

    .total,
    .email,
    .message {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-family: $fontSecondary;
      font-size: 2rem;
      margin: 1rem 0;

      .screen-msg-label {
        width: 10rem;
        margin: 0;
        flex: 0 0 auto;
      }
    }
  }
}

.screen-msg .total,
.screen-msg .total span {
  font-weight: 700;
}

.screen-msg .total a {
  color: $main;
  text-decoration: none;
  margin-left: 10px;
}

.billing-info h3 {
  font-family: $fontSecondary;
  font-size: 3rem;
  margin: 0 0 1rem;
  color: #fff;
  text-align: center;
}

// .screen-msg .email span {
//   margin-left: 17%; }

// .screen-msg .message span {
//   margin-left: 10%; }

.billing-info div label {
  font-family: $fontSecondary;
  display: block;
  font-size: 2rem;
  margin: 0 0 5px;
  color: #fff;
}

.billing-info .section-1 {
  margin-bottom: 5rem;
}

.billing-info .section-1 div {
  margin-top: 15px;
}

.billing-info .section-1 div input {
  //margin-top: -10px;
  width: 100%;
  padding: 10px;
}

.billing-info .section-2 .card-number {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: space-between;
  margin-bottom: 5%;
}

.billing-info .section-2 input {
  width: 100%;
  padding: 10px;
}

.billing-info .section-2 .card-n {
  width: 87%;
}

.billing-info .section-2 .cvc-n {
  width: 100%;
}

.billing-info .section-2 .expire-date input {
  width: 100%;
}

.footer-msg {
  font-size: 1.3rem;
  color: #fff;
  // font-family: 'Open Sans', sans-serif;
}

.footer-msg a {
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}

.stripe-fields {
  display: flex;
  flex-wrap: wrap;

  button {
    width: 100%;
    background: #fff;
  }
}

.stripe-field {
  margin-bottom: 15px;

  &.mod-no,
  &.mod-exp {
    width: 70%;
  }

  &.mod-cvc {
    width: 25%;
    margin-left: 5%;
  }
}

.stripe-field-label {
  font-family: $fontSecondary;
  display: block;
  font-size: 2rem;
  margin: 0 0 5px;
  color: #fff;
}

.stripe-element-container {
  padding: 5px 0;
  background: #fff;
}

.stripe-field-btn {
  width: 100%;
}
