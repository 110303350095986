@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';

.cart-dropdown-container {
  display: block;
  background: $white;
  padding: 0 2rem;
  @include smallScreen() {
    padding: 0;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    border-bottom: 0.1rem solid #eee;

    img {
      flex: 0.4;
      max-width: 20%;
      //object-fit: contain;
    }

    > div {
      flex: 1;
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 1rem;
      position: relative;

      > button {
        position: absolute;
        top: 0;
        right: 1rem;
      }

      p {
        font-family:
          Josefin Sans,
          sans-serif;
        font-weight: 400;
        font-size: $normalFont;
        color: $darkgray;
        margin: 0;
        text-align: left;
        &.-price {
          align-self: flex-end;
          font-weight: 600;
          padding-right: 1rem;
          font-size: $normalFont;
        }
        &.expire-msg {
          color: red;
        }
      }
    }
  }
}

.meal-cart-thumb {
  width: 20%;
  flex: 0.4;
  position: relative;
  @include image-loading-style;
  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
}
.meal-cart-thumb-src {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-size: cover;
}
