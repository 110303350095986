@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';

.success-message {
  text-align: center;
  margin: 0 auto;
  max-width: 420px;

  > p {
    color: #fff;
    font-size: 1.5rem;
    span {
      color: #fff;
    }
  }
  > a {
    display: block;
    color: #fff;
    font-size: 1.5rem;
    margin: 0 0 3rem;
  }
  .download-gift-pdf {
    display: block;
    color: #fff;
    font-size: 1.5rem;
    margin: 0 auto 3rem;
    appearance: none;
    cursor: pointer;
    border: 0;
    box-shadow: none;
    background: transparent;
    padding: 0;

    font-family: 'Montserrat-Light';
    font-weight: bold;
  }
  .screen-msg {
    padding: 3rem;
    background: rgba(255, 255, 255, 0.6);
    h2 {
      font-family: $fontSecondary;
      letter-spacing: 3px;
      font-size: 3rem;
      margin: 0 0 3rem;
    }
    .recipient,
    .amount,
    .message,
    .gift-code {
      width: 100%;
      display: flex;
      justify-content: center;
      font-family: $fontSecondary;
      font-size: 2rem;
      margin: 1rem 0;
      .screen-msg-label {
        width: 10rem;
        margin: 0;
        flex: 0 0 auto;
      }
      span {
        min-width: 10rem;
      }
    }
  }
}
