@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';
@import '../../styles/mixins.scss';
.steps-container {
  .step1-brand-title {
    margin-top: 4rem;
    @include mobileView() {
      margin: 2.5rem 0 1rem;
    }
  }
}
.step1-brand-container {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex: 1;
  flex-wrap: wrap;
}

.step1-brand {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid $lightgray;
  width: 30%;
  margin: 0 1.25%;
  margin-top: 2.5%;
  height: auto;

  @include mobileView() {
    width: 100%;
    max-width: 350px;
    margin: 2%;
  }
}

.step1-brand-head {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem 1rem;
  box-sizing: border-box;
}
.step1-brand-name {
  font-size: $normalFont;
  font-weight: bold;
}
.step1-brand-price {
  font-size: $smallFontMobile;
  font-weight: bold;
  white-space: nowrap;
}

.step1-brand-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  min-height: 45px;
  margin: 1rem 0 2rem;
  background-color: $main;
  font-family: 'Josefin Sans';
  font-size: $smallFont;
  color: $white;
}

.step1-brand-meal-img {
  position: relative;
  width: 100%;
  padding-bottom: 63.75%;
  @include image-loading-style;
}

.step1-brand-meal-img-src {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: no-repeat center center;
  background-size: cover;
}

.step1-brand-logo {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  $size: 75px;
  width: $size;
  height: $size;
  border-radius: 100%;
  overflow: hidden;
  background: #fff no-repeat center center;
  background-size: contain;
  right: 1rem;
  top: 1rem;
  z-index: 30;
  // width: 33%;
  // margin: 7%;
}

.step1-brand-logo-src {
  background: #fff no-repeat center center;
  background-size: contain;
  position: absolute;
  $size: 70%;
  width: $size;
  height: $size;
}

.step1-brand-content {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0 2rem;
  box-sizing: border-box;
  // padding-bottom: 5%;
  flex: 1;

  p {
    font-family: 'Josefin Sans';
    font-size: 1.5rem;
    text-align: left;
    line-height: 2.5;
    margin-top: 0;
    white-space: pre-wrap;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
