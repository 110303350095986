@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';

.gift-redeem {
  &-intro {
    text-align: center;
  }
  .text-success {
    padding-left: 0;
    padding-right: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: $main;
    font-family: 'Montserrat-Light', sans-serif;
  }
  .user-profile-title,
  .gift-redeem-intro-title {
    font-family: 'BebasNeue', sans-serif;
    font-size: 3rem;
    margin: 0 auto 2rem;
    color: $main;
  }
  .user-profile {
    padding: 5rem 0;
    text-align: center;
  }
  .user-profile-card {
    font-family: $fontPrimary;
    display: inline-flex;
    align-items: stretch;
    margin: 0 auto;
    border: 0.1rem solid silver;
    padding: 1rem;
    max-width: 360px;
    width: 100%;
  }
  .user-profile-icon {
    width: 75px;
    height: auto;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }
  .user-profile-icon-img {
    width: 100%;
  }
  .user-profile-id {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    margin-left: 1rem;
    border-left: 0.1rem solid silver;
  }
  .user-profile-id-container {
    padding: 0 3rem;
    text-align: left;
    font-size: 1.3rem;
    p {
      margin: 0.5rem 0;
    }
  }
  .gift-redeem-intro-desc {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 auto 2rem;
  }
  .signup-form {
    margin: auto;
    width: 90%;
    max-width: 640px;
    text-align: center;
    padding: 5rem 0;
  }
  .redeem-form {
    margin: auto;
    text-align: center;
    padding: 3rem 0;
    background: $lightgray;
  }
  .redeem-form-desc {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 auto 2rem;
    color: #000;
  }
  input {
    border-radius: 8px;
    background: #fff;
    outline: none;
    border-style: none;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    font-size: 1.5rem;
    font-family:
      Josefin Sans,
      sans-serif;
    outline: 0;
    width: 100%;
    margin: 0.5rem 0;
    padding: 12.5px 15px;
    height: 45px;
    &::placeholder {
      color: $gray100;
    }
  }
  .redeem-form-btn {
    @include green-btn();
    width: 20rem;
  }
  .redeem-form-inner {
    margin: auto;
    width: 90%;
    max-width: 640px;
  }
  .redeem-form-input {
    margin: 0 0 2rem;
    max-width: 300px;
    text-align: center;
  }
  .redeem-form-bottom {
    margin-top: 1rem;
    .redeem-form-desc {
      margin: 0 0 1rem;
    }
  }
  .checkbox-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0 0;
    #newsletter-subscribe-checkbox,
    input[type='checkbox'] {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 1rem;
      width: auto;
      height: auto;
    }
    label {
      display: block;
      width: 100%;
      text-align: left;
      font-size: 1.5rem;
    }
  }
}

.signupForm form {
  text-align: center;
  padding: 10px;
}
