@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 90vw;
  margin-bottom: 150px;
}

.container .title {
  font-size: 4rem;
  margin: 0 0 3rem;
  color: #fff;
}
.container .sub-title {
  font-size: 2rem;
  margin: 0 0 3rem;
  color: #fff;
}
.container .gift-form {
  justify-content: center;
  width: 90vw;
  max-width: 420px;
  color: #fff;
  text-align: center;
}
.container .gift-details-holder {
  padding: 5rem 3rem;
  background-color: #1e1e1e;
  box-shadow: -8px 8px 10px rgba(0, 0, 0, 0.7);
}
.container .gift-form .title {
  font-family: $fontSecondary;
  letter-spacing: 3px;
  margin: 0;
  font-size: 3rem;
}
.container .gift-form .sub-title {
  font-family: $fontSecondary;
  font-size: 5rem;
  letter-spacing: 0.1em;
  margin: 0 0 1rem;
}
.container .to {
  margin-top: -30px;
}
.container #gift-details .gift-form div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container #gift-details .message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container #gift-details .message textarea {
  resize: none;
  width: 310px;
  height: 150px;
  max-width: 70vw;
}
.container #gift-details input {
  @include placeholder() {
    text-align: left;
  }
  background: #fff;
  text-align: left;
  width: 250px;
  padding: 10px;
  border: none;
}
.container .gift-form div label {
  font-size: 2.5rem;
  font-family: $fontSecondary;
  margin-right: 20px;
}
.container #gift-details select {
  background: #fff;
  //margin-left: 5px;
  width: 250px;
  padding: 10px;
}
.container .gift-form div label {
  margin-top: 15px;
}
.container #gift-details .recipient,
.container #gift-details .price,
.container #gift-details .email {
  display: flex;
  label {
    width: 60px;
    padding-right: 10px;
    margin-right: 0;
    flex: 0 0 auto;
  }
}
.container #gift-details .recipient,
.container #gift-details .price,
.container #gift-details .email,
.container #gift-details .message {
  width: 100%;
  padding: 10px;
}
.container .button {
  position: relative;
  // margin-top: 3.5rem;
  // border: none;
  // width: 100%;
  // padding: 10px 15px;
  // color: #fff;
  // background-color: $main;
  // font-size: 3em;
  // font-weight: bold;
  // cursor: pointer;
  // transition: background-color .3s ease;
  @include green-btn();
  margin: 3.5rem auto 0;
}

.gift-form {
  * {
    box-sizing: border-box;
  }
}
