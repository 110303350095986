@import '../../styles/AppDesign.scss';
@import '../../styles/mixins.scss';
@import '../../styles/media-queries.scss';

/*--- original -----*/
.gift-cards-top {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url(/img/img-gift-cards-desktop-banner.jpg) no-repeat center
    center;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  @include smallScreen() {
    background-image: url(/img/img-gift-cards-mobile-banner.jpg);
    text-align: center;
    align-items: flex-start;
    padding-bottom: 15rem;
    .gift-redeem & {
      min-height: initial;
      padding-bottom: 5rem;
    }
    .gift-redeem.mod-success & {
      min-height: initial;
      padding-bottom: 20rem;
    }
  }
}
.gift-cards-top-content {
  color: #fff;
  padding: 0 5%;
  @include smallScreen() {
    padding-top: 2rem;
    width: 100%;
  }
}
.gift-cards-img {
  width: 70%;
  max-width: 200px;
  margin: 0 auto;
  display: none;
}

.gift-cards-title {
  font-size: 5rem;
  max-width: 640px;
  @include smallScreen() {
    font-size: 2.5rem;
  }
}

.gift-cards-label {
  margin: 0px 10px;
  // background:$main;
  // font-size: 3.5rem;
  // font-weight: bold;
  // color:#FFF;
  // text-align: center;
  // padding:1rem 3rem;
  // width:100%;
  // max-width:540px;
  // @include smallScreen() {
  //   display:inline-block;
  //   text-align: center;
  //   font-size: 2.5rem;
  //   padding:.5rem 1rem;
  // }
  @include green-btn();
}
.gift-cards-img-desc {
  display: flex;
  align-items: center;
  margin-top: 10rem;
  width: 125%;
  @include smallScreen() {
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 3rem;
  }
}
.gift-cards-tags {
  background: #212120;
  border-radius: 20px;
  font-size: 7rem;
  text-align: center;
  padding: 0.6em 0.75em 0.4em;
  margin-right: 5rem;
  box-shadow:
    0 19px 38px rgba(0, 0, 0, 0.5),
    -12px 10px 12px rgba(0, 0, 0, 0.35);
  white-space: nowrap;
  @include smallScreen() {
    margin: 0 auto 2rem;
    display: inline-block;
    font-size: 5rem;
  }
}
.gift-cards-tag {
  text-align: center;
  font-family: $fontSecondary;
  &.mod-s {
    font-size: 0.7em;
    letter-spacing: 0.0125em;
  }
  &.mod-m {
    font-family: 'BebasNeueLight';
    font-size: 0.8em;
    letter-spacing: 0.0125em;
  }
  &.mod-l {
    font-size: 1em;
    letter-spacing: 0.075em;
  }
}
.gift-cards-desc {
  font-size: 2.5rem;
  line-height: 1.5;
  max-width: 360px;
  @include smallScreen() {
    font-size: 1.25rem;
    max-width: none;
    max-width: initial;
  }
  .gift-cards-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 3rem;
    @include smallScreen() {
      margin: 2rem auto 0;
    }
  }
}

.gift-cards-instructions {
  padding: 8rem 3rem;
  background: #aaa;
  color: #000;
  text-align: center;
}
.gift-cards-instructions-title {
  font-family: $fontSecondary;
  font-size: 3.5rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  color: #000;
  margin: 0 0 1rem;
}

.gift-cards-instructions-desc {
  font-size: 1.5rem;
  max-width: 640px;
  margin: 0 auto 2rem;
  line-height: 1.5;
}
.gift-cards-btn {
  @include green-btn();
  &:link,
  &:visited,
  &:hover,
  &:active {
    font-weight: lighter;
  }
}

a:link,
a:visited,
a:hover,
a:active {
  color: white;
  font-weight: bold;
}
