@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 65%;
  color: $primary-text;
  font-size: $smallFont;

  .content {
    border-top: 1px solid #e2e2e2;
    display: flex;
    flex-direction: row;
    height: 50%;
    padding: 3rem 0;

    &-sm {
      display: none;
    }

    &.top {
      .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        width: 16.66%;

        span {
          color: $main;
        }

        a {
          color: $primary-text;
          &:hover {
            text-decoration: underline;
          }
        }

        span,
        a {
          width: 80%;
          text-decoration: none;
          text-align: left;
          margin-bottom: 2rem;
          font-size: $normalFont;
        }

        .logo {
          width: 75%;
          max-width: 200px;
        }

        .nosh-direct-logo {
          width: 100%;
          max-width: 200px;
        }

        .sm {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
          height: 100%;

          .sm-logo {
            width: 15%;
            max-width: 45px;
          }
        }
      }
    }

    &.bottom {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        margin: 0.8rem;
      }

      .payment-icon {
        margin-bottom: 2rem;
        width: 28%;
        max-width: 400px;
      }
    }
  }
}

@include mobileView() {
  footer {
    height: auto;
    .content {
      flex-wrap: wrap;
      height: auto;

      &.top {
        .col {
          width: 50%;
          span {
            font-size: $smallFont;
          }
          a {
            font-size: $smallFontMobile;
          }

          &.sm {
            display: none;
          }

          &.logo {
            display: none;
          }
        }
      }

      &-sm {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #e2e2e2;
        padding: 2rem;

        .logo {
          max-height: 45px;
        }

        .nosh-direct-logo {
          max-width: 80px;
        }

        .sm-logo {
          width: 20%;
          max-width: 75px;
          display: flex;
          justify-content: space-between;
          img {
            max-height: 24px;
          }
        }
      }

      &.bottom {
        height: auto;
        p {
          font-size: $normalFontMobile;
        }
        .payment-icon {
          width: 60%;
          max-width: 380px;
        }
      }
    }
  }
}
