@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';
@import '../../styles/mixins.scss';

section.corporate-meals {
  &-header {
    background-image: url('/img/nosh-catering-hero.jpg');

    .content {
      background-color: $main;
      justify-content: space-evenly;
      padding: 3rem 0;
      width: auto;
      max-width: 500px;

      .section-title,
      h1 {
        color: $white;
        font-family: 'BebasNeueRegular';
      }

      p {
        color: $white;
        text-align: center;
        width: 90%;
        margin: 2rem 0;
        font-size: $normalFont;
        line-height: 2;
        font-family: 'Josefin Sans';
      }

      a {
        button {
          background-color: $white;
          color: $main;
          margin: 0;
        }
      }
    }
  }

  &-features {
    .features {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .feature {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 33.333%;

        img {
          width: 80%;
          max-width: 350px;
        }

        p {
          font-size: $normalFont;
          width: 60%;
          text-align: center;
        }
      }
    }
  }

  &-catering-menu {
    background-image: url('/img/seeourmenu.jpg');

    .content {
      margin-left: 10%;
      width: 50%;
      justify-content: center;
      align-items: flex-start;

      .section-title,
      h1 {
        font-family: 'BebasNeueRegular';
      }

      p {
        font-family: 'Josefin Sans';
        font-size: $normalFont;
        margin-bottom: 3rem;
      }

      .buttons {
        display: flex;
        align-items: center;

        a {
          button {
            margin-top: auto;
            padding: 1rem;
            height: auto;
          }
          &:not(:first-child) {
            position: relative;
            margin-left: 3rem;
          }
        }
      }
    }
  }

  &-our-brands {
    background-image: url('/img/nosh-ga.jpg');

    .content {
      .section-title,
      h1 {
        font-family: 'BebasNeueRegular';
      }
      p {
        width: 30%;
        text-align: center;
        font-family: 'Josefin Sans';
        font-size: $normalFont;
        line-height: 2;
      }

      a {
        button {
          margin-top: auto;
        }
      }
    }
  }
}

.mobileView {
  @include mobileView() {
    &.corporate-meals {
      &-header {
        height: auto;
        max-height: none;

        .content {
          width: 90% !important;
          margin: 10rem auto;
          padding: 3rem 0;

          .section-title,
          h1 {
            text-align: center;
            width: 80%;
            line-height: 1.2;
          }
        }
      }

      &-features {
        height: auto;
        max-height: unset;
        .content {
          margin: 15% 0;
          justify-content: space-evenly;
          .features {
            flex-direction: column;
            .feature {
              margin: 5% 0;
              width: 100%;
            }
          }
        }
      }

      &-catering-menu {
        height: auto;
        max-height: unset;
        .content {
          padding: 10%;
          width: 100% !important;
          margin: 0 !important;
          align-items: center !important;

          .section-title,
          h1 {
            text-align: center;
          }

          p {
            width: 100%;
            text-align: center;
          }

          .buttons {
            flex-direction: column;

            a {
              button {
                margin-top: auto;
                padding: 1rem;
                height: auto;
              }
              &:not(:first-child) {
                margin-left: 0 !important;
                margin-top: 1.5rem;
              }
            }
          }
        }
      }

      &-our-brands {
        height: auto;

        .content {
          padding: 10%;
          p {
            width: 100% !important;
          }
        }
      }
    }
  }
}
