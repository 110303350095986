@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.steps-container.static-menu {
  margin-top: 0;
}

.static-menu-content {
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.static-menu-inner {
  width: 100%;
  text-align: center;

  .step3-text {
    font-family: 'Josefin Sans', sans-serif;
    color: $darkgray;
  }

  .show-mobile {
    display: none !important;
  }

  .bottom-border {
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
    border-bottom: $lightgray solid 0.1rem;
  }

  .step3-label-text {
    font-size: $normalFont;
    font-family: 'Josefin Sans', sans-serif;
    color: $darkgray;

    .week-pickers & {
      margin-bottom: 1rem;
    }
  }

  .relative {
    position: relative;
  }

  //plan-info
  .plan-info {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  .plan-info-box {
    width: 100%;
    margin: 0 auto;
    border: 0.1rem solid $lightgray;
    padding: 3rem 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .plan-info-brand {
    // font-size: $main;
    // font-weight: 400;
    // font-family: "Josefin Sans", sans-serif;
    // color: $darkgray;
    margin: 0 0 1rem;
  }

  .plan-info-title {
    // font-size: $bigFont;
    // font-weight: 400;
    // font-family: "Josefin Sans", sans-serif;
    // color: $darkgray;
    margin: 0;
  }

  .plan-info-price {
    // font-size: $bigFont;
    // font-weight: 700;
    // font-family: "Josefin Sans", sans-serif;
    // color: $darkgray;
    margin: 0 0 1rem;
  }

  .plan-info-per-day {
    margin: 0;
    // font-size: $smallFont;
    // color: $darkgray;
    text-align: right;
  }

  .plan-info-brand,
  .plan-info-title,
  .plan-info-price,
  .plan-info-per-day {
    font-family: $fontPrimary;
    font-size: $largeFont;
    color: $darkgray;
    font-weight: normal;
  }

  .plan-info-price {
    font-weight: bold;
  }

  .plan-info-helper {
    //position:absolute;
    width: 100%;
    text-align: right;
    padding: 1rem 0;
  }

  .plan-info-helper-link {
    text-align: right;
    color: $main;
    //text-transform: uppercase;
  }

  .step3-container {
    display: flex;
    flex-flow: column;
    width: 90%;
    margin-top: 4rem;
    max-width: 1000px;

    .left-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex: 0.45;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .nutrition-container {
      display: flex;
      flex-direction: column;
      //margin-top: 3rem;
      align-items: center;
      justify-content: space-evenly;
      border: $lightgray solid 0.1rem;
      max-width: 200px;
      //margin: 2rem auto 1rem;
      padding: 2rem 2rem;
      margin-left: 5rem;

      .left-border {
        //border-left: $lightgray solid 0.1rem;
        width: 100%;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .nutrition-title {
        width: 100%;
        margin-bottom: 1rem;
      }

      div {
        display: flex;
        //flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 1.5rem;

        .nutrition-main-label {
          font-family: 'BebasNeue', sans-serif;
          color: $darkgray;
          font-size: $mediumFont;
          //text-align: center;
          padding: 0;
          white-space: nowrap;
        }

        .nutrition-label {
          font-family: 'BebasNeue', sans-serif;
          color: $main;
          font-size: $normalFont;
          padding: 0;
        }

        .nutrition-value {
          font-family: 'BebasNeue', sans-serif;
          color: $darkgray;
          font-size: $smallFont;
          padding: 0 1.5rem;
        }
      }
    }

    .right-container {
      display: flex;
      //margin-left: 3rem;
      flex-direction: column;
      flex: 0.55;

      .start-date-container {
        // display: flex;
        // flex-direction: row;
        margin-top: 3rem;

        .start-date {
          font-size: $normalFont;
          font-weight: bold;
          color: $darkgray;
          font-family: 'Josefin Sans', sans-serif;
          margin-bottom: 1rem;
        }

        .date-range {
          margin-left: 4rem;
          font-size: $smallFont;
        }

        .change-date {
          margin-top: 2rem;
          padding: 0;
          background-color: transparent;
          border: transparent none 0;
          color: $main;
          font-size: $normalFont;
        }

        .change-date:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .meal-options-container {
        //margin-top: 4rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        $gutter: 3rem;
        margin: 2rem #{-$gutter/2} 0;

        * {
          box-sizing: border-box;
        }

        .meal-options-item {
          width: 50%;
          flex: 0 0 auto;
          flex-direction: row;
          padding: 0 $gutter/2;

          .meal-options-choice-container {
            margin-top: 1rem;
            height: 4rem;
            background: transparent;
            border-radius: 0;
            border: rgba($lightgray, 0.5) solid 0.1rem;
            align-items: center;
            flex-direction: row;
            display: flex;
            justify-content: space-between;
            position: relative;
          }

          button {
            width: 20%;
            align-self: center;
            font-size: $smallFont;
            font-weight: bold;
            height: 100%;

            &.is-disabled {
              pointer-events: none;
              opacity: 0.6;
            }
          }

          button:hover {
            transition: 0.3s;
            background-color: $lightgray;
          }

          .num-people-text {
            text-align: center;
            font-size: $smallFont;
          }

          select {
            -webkit-appearance: none;
            height: 100%;
            width: 100%;
            background: transparent;
            outline: none;
            border-radius: 0;
            border: transparent none 0;
            font-size: $smallFont;
            font-family: 'Josefin Sans', sans-serif;
            padding: 0 15px;
          }

          .caret-down {
            position: absolute;
            right: 15px;
            margin-left: 0.5rem;
            font-size: 1.5em;
          }

          .special-request-button {
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: row;
            font-size: $smallFont;
            flex: 1 1;

            span {
              color: $darkgray;
              margin-top: 0.5em;
            }

            img {
              height: 2rem;
              margin-left: 1rem;
            }
          }

          .special-request-button:hover {
            transition: 0.3s;
            background-color: $main;
            color: $white;

            span {
              color: $white;
            }
          }
        }
      }

      .right-bottom-group {
        margin-top: 3rem;
        margin-bottom: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .static-menu & {
          margin: 0;
        }

        .free-snack-text {
          font-family: 'BebasNeue', sans-serif;
          font-size: $largeFont;
          color: $main;
        }

        .smaller {
          font-size: $mediumFont;
          color: $main;
        }
      }

      .checkout-container {
        display: flex;
        align-self: flex-end;
        flex-direction: column;

        .total-text {
          color: $darkgray;
          font-family: 'Josefin Sans', sans-serif;
          font-size: $smallFont;
          text-align: right;
          font-weight: 400;

          .add-to-cart {
            display: inline;
            color: $main;
            background-color: transparent;
            border: transparent none 0;
            margin: 0;
            padding: 0;
          }

          .add-to-cart:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        button {
          background-color: $main;
          align-items: center;
          height: 4rem;
          margin: 1rem 0;
          border-radius: 0.2rem;
          padding: 0 3rem;
          color: white;
          font-size: $normalFont;
          font-family: 'Josefin Sans', sans-serif;

          &.is-disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        }

        button:hover {
          transition: 0.3s;
          background-color: $lightgreen;
        }
      }
    }
  }

  .step3-container .right-container .meal-options-container.mod-top-options {
    //align-items: center;
    .meal-options-item {
      width: 50%;
    }

    .meal-options-choice-container {
      //max-width:313.3333px;
    }
  }

  .meals-per-day-group {
    width: 50%;
    //margin-left: 4rem;
    padding: 0 1.5rem;

    input[type='checkbox'] {
      background-color: initial;
      cursor: default;
      -webkit-appearance: checkbox;
      box-sizing: border-box;
      margin: 3px 0.5ex;
      padding: initial;
      border: initial;
    }

    input[type='checkbox']:checked {
      background-color: $main;
      border-color: $main;
    }

    .meals-per-day-button-group {
      display: flex;
      flex: 0.6;
      // justify-content: space-between;
      flex-direction: row;
      margin-top: 1rem;
      padding-left: 0;

      .checkbox-button-container {
        line-height: 40px;
        margin-bottom: 0;
        padding-right: 0;
      }

      .meals-per-day-msg {
        padding-left: 0.2rem;

        p {
          margin: 0;
        }
      }

      div {
        display: flex;

        input[type='checkbox'] {
          opacity: 0;
        }
      }
    }
  }

  .meals-per-day-msg {
    // margin: 1.5rem 0 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    color: $main;
    font-size: 1.15rem;

    span {
      display: block;
      color: $main;
      font-size: 1.3rem;

      @include mobileView {
        display: inline-block;
      }
    }
  }

  .meals-per-day-warning {
    // margin: 1.5rem 0 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    color: red;
    font-size: $smallFont;
  }

  .meals-per-day-msg .meals-per-day-msg-comma {
    margin-right: 1rem;
    display: none;

    &:last-child {
      display: none;
    }

    @include mobileView {
      display: inline-block;

      &:last-child {
        display: none;
      }
    }
  }

  //radio
  .radio-pickers {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .static-menu & {
      margin-top: 3rem;

      @include mobileView {
        margin-top: 0;
      }
    }
  }

  .radio-pickers-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &.week-pickers {
      justify-content: space-between;

      .radio-picker {
        width: auto;
      }
    }
  }

  .radio-pickers-breaker {
    width: 100%;
  }

  .radio-picker {
    position: relative;
    width: 33.33333%;
    margin-bottom: 1rem;

    input[type='radio'] {
      display: none;
    }

    input[type='radio']:checked {
      & + .radio-picker-label {
        &:after {
          opacity: 1;
        }
      }
    }

    &.is-disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .radio-picker-txt {
    display: inline-block;
    width: 240px;
    font-size: $smallFont;
    font-weight: bold;

    .week-picker & {
      color: $lightgray;
    }

    .week-picker input[type='radio']:checked + .radio-picker-label & {
      color: $main;
    }
  }

  .radio-picker-label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      $size: 15px;
      width: $size;
      height: $size;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -$size/2 - 2px;
      border: 1px solid $main;
      border-radius: 50%;
      transform-origin: center center;
    }

    &:before {
      border: 1px solid $lightgray;
    }

    &:after {
      border: 1px solid $main;
      background: $main;
      transform: scale(0.5);
      opacity: 0;
    }
  }

  .nutrition-main-hyphen {
    display: none;
  }

  @include mobileView() {
    .hide-mobile {
      display: none !important;
    }

    .show-mobile {
      display: block !important;
    }

    .step3-container {
      flex-direction: column;

      .right-container {
        margin-left: 0;
        margin-top: 4rem;
        padding: 0;

        .static-menu & {
          margin-top: 0;
        }

        .options-menu-container {
          flex-direction: column;
          margin-top: 4rem;

          .options-menu-item {
            margin-top: 4rem;
          }

          .options-menu-choice-container {
            .how-many-people {
              .num-people-button {
                flex: 0.1;
              }
            }
          }
        }

        .start-date-container {
          padding-bottom: 1rem;
          border-bottom: $lightgray solid 0.1rem;
        }

        .meal-options-container {
          flex-direction: column;

          .meal-options-item {
            margin-bottom: 2rem;

            .meal-options-choice-container {
              align-items: center;

              select {
                text-align: center;
              }

              .caret-down {
                right: 3rem;
              }
            }
          }
        }

        .right-bottom-group {
          margin: 2rem 0;
          border-bottom: $lightgray solid 0.1rem;
          // .meals-per-day-group {
          //   display: flex;
          //   flex-direction: column;

          //   .meals-per-day-button-group {
          //     flex-direction: column;
          //     justify-content: space-evenly;
          //   }
          // }
        }

        .checkout-container {
          align-self: center;
          align-items: center;

          .price {
            font-size: $normalFont;
            color: $lightgray;
            font-weight: bold;
            font-family: 'Josefin Sans', sans-serif;
          }

          .total-price {
            margin: 1rem 0;
            font-size: $bigFont;
            color: $darkgray;
            font-weight: bold;
            font-family: 'Josefin Sans', sans-serif;
          }
        }

        .bottom-nutrition-info {
          justify-content: center;
          display: flex;
          padding-top: 2rem;
          border-top: $lightgray solid 0.1rem;

          .bottom-nutrition-header {
            text-align: center;
            color: $darkgray;
            font-family: 'BebasNeue', sans-serif;
            font-size: $mediumFont;
          }

          .bottom-nutrition-container {
            margin-top: 1rem;
            align-items: center;
            justify-content: space-between;
            display: flex;
            flex-direction: row;

            div {
              display: flex;
              flex-direction: column;
              align-items: center;

              .nutrition-label {
                color: $main;
                font-family: 'BebasNeue', sans-serif;
                font-size: $normalFont;
              }
            }
          }
        }
      }
    }

    .plan-info-box {
      flex-flow: column;
      padding: 2rem 2rem;
    }

    .plan-info-name {
      margin-bottom: 2rem;
    }

    .plan-info-name,
    .plan-info-price-holder {
      width: 100%;
    }

    .plan-info-price-holder {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .plan-info-price {
      font-size: $largeFont;
      margin-bottom: 0;
      padding-right: 1.5rem;
    }

    .nutrition-title {
      width: 100%;
    }

    .nutrition-container {
      padding: 2rem;
      margin-left: 2rem;
    }

    // .step3-container .left-container .nutrition-container .left-border{
    //   border:0;
    //   width:auto;
    //   box-sizing: border-box;
    //   padding:1rem 0;
    // }
    .step3-container .right-container .meal-options-container {
      margin-top: 1rem;
    }

    .step3-container
      .right-container
      .meal-options-container
      .meal-options-item {
      width: 100%;
      box-sizing: border-box;
    }

    .step3-container .right-container .right-bottom-group {
      display: block;
    }

    .radio-pickers-row {
      flex-flow: column;
    }

    .radio-picker {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;

      .static-menu & {
        justify-content: flex-start;
      }
    }

    .step3-container .right-container .right-bottom-group {
      border-bottom: 0;
    }

    .step3-container .right-container .bottom-nutrition-info {
      position: relative;
      border-top: 0;
      padding-bottom: 2rem;
      margin-bottom: 2rem;

      &:before {
        content: '';
        position: absolute;
        width: 200vw;
        height: 100%;
        top: 0;
        left: -50vw;
        background: #fafafa;
        z-index: 5;
        pointer-events: none;
      }
    }

    .step3-container .right-container .checkout-container {
      margin-top: 2rem;

      .static-menu & {
        margin-top: 0;
        margin-bottom: 2rem;
      }
    }

    .step3-container .right-container .checkout-container .total-text,
    .step3-container .right-container .checkout-container .price {
      text-align: center;
    }

    .step3-container .right-container .checkout-container .price {
      margin-bottom: 1rem;
    }

    .step3-container .right-container .checkout-container .total-text {
      font-size: $largeFont;
    }

    .step3-container
      .right-container
      .meal-options-container.mod-top-options
      .meal-options-item {
      width: 100%;
    }

    .meals-per-day-group {
      width: 100%;
    }

    .meals-per-day-group .meals-per-day-button-group {
      width: 100%;
      flex-wrap: wrap;
    }

    .meals-per-day-msg,
    .meals-per-day-warning {
      width: 100%;
    }
  }

  @include smallScreen() {
    .step3-container .left-container {
      flex-flow: column;
    }

    .step3-container .left-container,
    .nutrition-container-mob {
      .nutrition-container {
        width: 100%;
        max-width: 100%;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: 0;
        box-sizing: border-box;
        border: 0;
        padding: 1.5rem 0;
      }

      .nutrition-container div {
        padding: 0;
      }

      .nutrition-container .nutrition-title {
        text-align: center;
        justify-content: center;
        align-self: center;
        margin-bottom: 0.5rem;
      }

      .nutrition-container .left-border {
        width: auto;
        margin-bottom: 0;
      }

      .nutrition-container div .nutrition-main-label {
        white-space: normal;
      }

      .nutrition-main-hyphen {
        font-family: $fontSecondary;
        color: #4a4a4a;
        font-size: 2rem;
        padding: 0;
        display: block;
        margin: 0 1rem;
      }
    }

    .meals-per-day-group
      .meals-per-day-button-group
      .checkbox-button-container {
      padding-left: 0;
    }
  }

  .custom-select {
    width: 100%;

    > div {
      border: 0;
      font-size: 16px;
    }
  }

  .meal-options-seperater {
    width: 100%;
    //max-width:200px;
    height: 1px;
    background: rgba($lightgray, 0.5);
    margin: 4rem auto;
  }

  .step3-container
    .right-container
    .meal-options-seperater
    + .meal-options-container {
    margin-top: 0;
  }

  .meal-table-tophead {
    position: relative;
    background: $yellow;
    color: #000;
    text-align: center;
    font-size: $largeFont;
    padding: 1.5rem;
    font-weight: bold;
    //border-bottom:1px solid $darkgray;
    margin-bottom: 2rem;
  }

  .plan-info-name {
    //margin-bottom:2rem;
    display: table;

    .plan-info-brand,
    .plan-info-title {
      display: table-row;

      > span {
        display: table-cell;
        padding-right: 0.5rem;
      }
    }
  }

  .meal-table-mob-hints {
    text-align: center;
    font-size: $smallFont;
    font-style: italic;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .meals-per-day-iconmsg {
    position: relative;
    padding-left: 20px;
    text-transform: uppercase;

    &.mod-breakfast-discount {
      padding-left: 0;

      .meals-per-day-iconmsg-icon {
        position: relative;
        width: 24px;
        transform: none;
      }
    }
  }

  .meals-per-day-iconmsg-icon {
    position: absolute;
    left: 0;
    width: 18px;
    transform: translate(0, -5%);
  }

  .our-menu-header {
    height: 150px;
    background-color: $main;
  }

  .our-menu-title {
    text-align: center;
    color: $white;
    font-family: 'Josefin Sans', sans-serif;
    font-size: $largeFont + 2rem;
    margin: 2rem 0;
  }

  .our-menu-desc {
    text-align: center;
    color: $white;
    font-family: 'Josefin Sans', sans-serif;
    font-size: $normalFont;
    margin: 0 auto;
    max-width: 768px;
  }

  .our-menu-intro {
    text-align: left;
    font-family: 'Josefin Sans', sans-serif;
    font-size: $normalFont;
    margin: 0 auto 1rem;
    //max-width:768px;
  }
}
