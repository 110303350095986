@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.loading-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10001;
  background: rgba(#fff, 0.6);
}
.loading-screen-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 40%;
}
