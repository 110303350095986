@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';

.forgot-password-container {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  max-width: 300px;
  align-items: stretch;

  > h1 {
    font-size: $mediumFont;
    text-align: center;
    font-family:
      Josefin Sans,
      sans-serif;
  }

  > p {
    text-align: left;
    font-family:
      Josefin Sans,
      sans-serif;
    font-size: $normalFont;
    color: $black;
    line-height: 1.75rem;
  }

  > label {
    color: $darkgray;
    font-size: $normalFont;
    font-family:
      Josefin Sans,
      sans-serif;
  }

  > input {
    padding: 1rem;
    margin: 0.5rem 0;
    border-radius: 0.75rem;
    outline: none;
    border: #e2e2e2 solid 1px;
    font-size: $normalFont;
  }

  .reset-pw-button {
    margin: 2rem 0;
    width: 100%;
    font-weight: 400;
    border-radius: 8px;
    font-family:
      Josefin Sans,
      sans-serif;
    background-color: #587d62;
    color: #fff;
    font-size: $normalFont;
    padding: 12px;
    outline: none;
    cursor: pointer;
    border-color: #fff;
    border-width: 0 1px;
    border-style: solid;

    &:hover,
    &:active,
    &:focus {
      background: #78b9b0;
    }
  }

  .popup-options-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;

    > button {
      font-size: $smallFont;
      color: $main;
      font-family:
        Josefin Sans,
        sans-serif;
      font-weight: 500;
    }
  }

  .resetpw-further-instructions {
    color: red;
    font-size: $smallFont;
  }
}
