@import './styles/AppDesign.scss';

html {
  /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -webkit-touch-callout: none; */
}

body:before {
  display: none;
}

body {
  margin: 0;
  font-family: 'Montserrat-Light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: none;
}
