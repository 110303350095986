@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';
.home-ambassadors {
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;
  height: auto;
  display: block;
  // @include mobileView() {
  //   display:block;
  // }
}
.home-ambassadors-inner {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 3rem 3rem;
  @include mobileView() {
    padding: 3rem 3rem;
  }
}
.home-ambassadors-title {
  margin: 0 auto 3rem;
  font-family: $fontSecondary;
  color: $main;
  font-size: $bigFont;
  @include mobileView() {
    font-size: $mediumFont;
    font-weight: bold;
  }
}
.home-ambassadors-slider {
  margin: 0 auto 3rem;
  position: relative;
  width: 100%;
  .swiper-container {
    overflow: visible;
  }
  .swiper-wrapper {
    width: auto;
    margin-left: -10px;
    margin-right: -10px;
  }
}
.home-ambassadors-slider-item {
  box-sizing: border-box;
  // padding:0 10px;
  // &:last-child{
  //   padding-right:0;
  // }
}
.home-ambassadors-slider-img {
  max-width: 135px;
  margin: 0 auto 2rem;
  background-size: cover;
  @include mobileView() {
    max-width: 100px;
  }
  &:before {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
}
.home-ambassadors-slider-item-title {
  margin: 0 auto 0.5rem;
  font-family: $fontPrimary;
  font-size: $smallFont;
  font-weight: bold;
  color: #000;
}
.home-ambassadors-slider-item-desc {
  margin: 0 auto;
  font-size: $smallFontMobile;
}
.home-ambassadors-btn {
  font-size: $normalFont;
  font-family:
    Josefin Sans,
    sans-serif;
  font-weight: lighter;
  color: $white;
  background-color: $main;
  padding: 0;
  border: none;
  width: 16rem;
  height: 4rem;
  @include mobileView() {
    height: 3rem;
  }
}
