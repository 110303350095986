@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries';

.terms-main-container {
  font-family: 'Josefin Sans', sans-serif;
  color: #4a4a4a;
  width: 100%;

  .terms-header {
    padding: 40px;
    margin-top: 50px;
    background-color: $main;

    h1 {
      font-size: $largeFont + 2rem;
      color: white;
      text-align: center;
      @include smallScreen() {
        font-size: $largeFont + 1rem;
      }
    }
  }

  .terms-conditions-content {
    font-size: 16px;
    width: 80%;
    padding: 36px 10%;
    text-align: left;
    .terms-content-title {
      font-family: 'BebasNeue', sans-serif;
      font-size: 26px;
      padding-bottom: 20px;
      padding-top: 10px;
    }
    .terms-content-desc {
      padding: 0px 30px;
      line-height: 24px;
      color: #000;
      text-align: justify;
    }
    .content-list {
      padding-left: 15px;
    }
    a:visited,
    a:link {
      color: #587d62;
      text-decoration: none;
    }
  }
}
