@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    flex: 0.2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem 4rem;
    border: $main solid 0.15rem;

    .icon {
      color: $main;
      margin: 0 1rem;
    }

    div {
      color: $main;
      font-size: $normalFont;
      font-family: 'Josefin Sans', sans-serif;
    }
  }

  .footer-header {
    font-size: $largeFont;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    margin: 2rem 0;
  }

  .footer-details {
    font-family: 'Josefin Sans', sans-serif;
    font-size: $smallFont;
    text-align: center;
    margin-bottom: 2rem;

    div {
      display: inline;
      color: $main;
    }

    div:hover {
      text-decoration: underline;
    }
  }

  .footer-detail-card-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;

    .footer-detail-card {
      div {
        font-family: 'Josefin Sans', sans-serif;
        font-size: $smallFont;
      }
    }
  }

  .separator {
    width: 10%;
    border-bottom: $main solid 0.1rem;
    margin: 2rem 0;
    align-self: center;
  }

  .footer-details-text {
    width: 60%;
    text-align: center;
    align-self: center;
    font-family: 'Josefin Sans', sans-serif;
    color: $darkgray;
    font-size: $normalFont;
  }
}

.meal-table {
  position: relative;
  width: 90%;
  max-width: 1000px;
  margin-top: 2rem;
  z-index: 0;
}
.meal-table-body {
  width: 100%;
  display: flex;
  flex-flow: column;
  border: 1px solid $darkgray;
  text-align: center;
  * {
    box-sizing: border-box;
  }
}
.meal-table-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  &:first-child {
    border-bottom: 1px solid $lightgray;
  }
  &:nth-child(2n) {
    background: $lightgraybg;
  }
  &.not-selected,
  &.is-skipped,
  &.is-holiday {
    .meal-table-weekday-name,
    .meal-table-meal,
    .meal-table-infos {
      > * {
        opacity: 0.2;
      }
    }
  }
  &.not-selected,
  &.is-skipped {
    .meal-table-weekday-name,
    .meal-table-meal,
    .meal-table-infos {
      > * {
        .static-menu & {
          opacity: 1;
        }
      }
    }
  }
}
.meal-table-weekday {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  border-right: 1px solid $lightgray;
  padding: 1rem;
}
.meal-table-weekday-name {
  font-weight: bold;
  font-size: $normalFont;
  @include mobileView() {
    font-size: $normalFontMobile;
  }
}
.meal-table-meals {
  width: 65%;
  display: flex;
}
.meal-table-meal-content {
  width: 100%;
}

.meal-table-noresult {
  margin: 3rem 0;
  font-size: $largeFont;
  font-weight: bold;
}
.meal-table-mealtype {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33333%;
  padding: 1rem;
  .meal-table.mod-has-snack & {
    @mixin extraLargeScreen() {
      width: 25%;
    }
    @mixin largeScreen() {
      width: 25%;
    }
  }
  &.mod-snack {
    display: none;
    .meal-table.mod-has-snack & {
      display: flex;
    }
  }
}
.meal-table-mealtype-icon {
  $size: 30px;
  width: $size;
  margin-right: 5px;
  &.mod-snack {
    width: 22px;
    margin-right: 8px;
  }
}
.meal-table-mealtype-label {
  font-weight: bold;
}
.meal-table-mealtype-label {
  font-weight: bold;
}
.meal-table-infos {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  padding: 1rem 3rem;
  border-left: 1px solid $lightgray;
}
.meal-table-infos-head {
  font-weight: bold;
}
.meal-table-meal {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: top;
  width: 33.3333%;
  padding: 2rem 1rem;
  .meal-table.mod-has-snack & {
    @mixin extraLargeScreen() {
      width: 25%;
    }
    @mixin largeScreen() {
      width: 25%;
    }
  }
  &.mod-snack {
    display: none;
    .meal-table.mod-has-snack & {
      display: flex;
    }
  }
  .checkbox-button-container {
    padding-right: 0;
  }
  .checkbox-label {
    display: none;
  }
}
.meal-table-meal-img {
  width: 120px;
  background: url() no-repeat center center;
  background-size: cover;
  margin: 0 auto;
  flex: 0 0 auto;
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}
.meal-table-meal-name {
  margin: 1rem 0 0.5rem;
}
.meal-table-meal-calories {
  margin: 0;
  //white-space: nowrap;
}
.meal-table-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100px;
}
.meal-table-info-name,
.meal-table-info-data {
  margin: 0.25rem 0;
}

.meal-table-info-name {
  font-size: $smallerFont;
  font-weight: bold;
}
.meal-table-info-data {
  font-family: $fontSecondary;
  font-size: $mediumFont;
  font-weight: bold;
}

.meal-table-skip-btn {
  display: inline-block;
  height: 3rem;
  margin: 1rem 0;
  border-radius: 0.2rem;
  padding: 0 1rem;
  font-size: $smallerFont;
  font-family: 'Josefin Sans', sans-serif;
  border: 1px solid $lightgray;
  z-index: 100;
  .static-menu & {
    display: none;
  }
}

.meal-table-meal-type {
  font-weight: bold;
}

.meal-table-toggle {
  display: none;
}

@include mobileView() {
  .container {
    .footer-details-text {
      width: 90%;
    }
  }
  .meal-table-body {
    border: 0;
  }
  .meal-table-row {
    flex-flow: column;
  }
  .meal-table-row:nth-child(2n) {
    background: transparent;
  }
  .meal-table-tophead {
    border-bottom: 0;
    &:before {
      content: '';
      position: absolute;
      width: 200vw;
      height: 100%;
      top: 0;
      left: -50vw;
      background: $yellow;
      border: 1px solid $yellow;
      z-index: -1;
      pointer-events: none;
    }
  }

  .meal-table-toggle {
    position: absolute;
    width: 200vw;
    height: 100%;
    top: 0;
    left: -50vw;
    display: block;
    font-size: 0;
    opacity: 0;
    z-index: 100;
    .meal-table-row.is-holiday & {
      display: none;
    }
  }
  .meal-table-weekday {
    position: relative;
    width: 100%;
    border: 0;
    padding: 2rem 0;
    text-align: left;
    align-items: flex-start;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform-origin: center center;
      margin-top: -5px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 8px 0 8px;
      border-color: $main transparent transparent transparent;
      .meal-table-row.is-open & {
        transform: rotate(180deg);
      }
      .meal-table-row.is-holiday & {
        display: none;
      }
    }
  }
  .meal-table-weekday:before,
  .meal-table-body:after {
    content: '';
    position: absolute;
    width: 200vw;
    height: 1px;
    left: -50vw;
    background: $lightgray;
    z-index: -1;
    pointer-events: none;
  }
  .meal-table-weekday:before {
    top: 0;
  }
  .meal-table-body:after {
    bottom: 0;
  }
  .meal-table-weekday-name {
    width: 100%;
  }
  .meal-table-meals {
    width: 100%;
    flex-flow: column;
    align-items: center;
    display: none;
    .meal-table-row.is-open & {
      display: flex;
    }
  }
  .meal-table-meal,
  .meal-table-meal-content {
    width: 100%;
  }
  .meal-table-infos {
    position: relative;
    display: none;
    border-left: 0;
    padding: 2rem 0;
    &:before {
      content: '';
      position: absolute;
      width: 200vw;
      height: 100%;
      top: 0;
      left: -50vw;
      background: #fafafa;
      z-index: -1;
      pointer-events: none;
    }
    .meal-table-row.is-open & {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
  .meal-table-infos-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'BebasNeue', sans-serif;
    color: $darkgray;
    font-size: $mediumFont;
    //text-align: center;
    padding: 0;
    margin: 0 0 1rem;
    white-space: nowrap;
  }
  .meal-table-infos-header-hyphen {
    font-family: $fontSecondary;
    color: #4a4a4a;
    font-size: 2rem;
    padding: 0;
    display: block;
    margin: 0 1rem;
  }
  .meal-table-info {
    width: auto;
    //flex-wrap: wrap;
  }
  .meal-table-info-name {
    font-family: $fontSecondary;
    font-size: $normalFont;
    color: $main;
    font-weight: normal;
    //width:100%;
  }
  .meal-table-info-data {
    font-family: $fontSecondary;
    font-size: $normalFont;
    font-weight: normal;
    padding: 0 1.5rem;
    //width:100%;
  }
  .meal-table-meal-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .meal-table-meal-info {
    width: 100%;
    text-align: left;
    padding-left: 2rem;
    &:first-child {
      padding-left: 0;
    }
  }
}

.meal-table-weekday-date {
  display: block;
  font-size: $smallFont;
  margin-top: 1rem;
  @include mobileView() {
    font-size: $normalFontMobile;
  }
}
.meal-table-weekday-holiday {
  display: block;
  font-size: $smallFont;
  margin-top: 1rem;
}
.meal-table-nosnack {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.17em;
  font-weight: bold;
  @include mobileView() {
    width: 100%;
    justify-content: flex-start;
    text-align: left;
    padding: 1rem 0;
    display: none;
  }
}
