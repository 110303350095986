@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';

.vita-link {
  color: blue !important;
}

.vita-text {
  font-size: 1.6rem;
  font-weight: normal;
  margin: 1rem 0;
  align-self: flex-start;
}

.vita-promo {
  font-family: 'Josefin Sans', sans-serif;
}

.vita-banner-img {
  width: 100vw;
  margin-bottom: -3px;
}

.vita-heading {
  height: 150px;
  width: 100%;
  background-color: $main;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vita-title {
  text-align: center;
  color: $white;
  font-size: $largeFont + 2rem;
  font-weight: normal !important;
  padding: 0 30px;

  @include mediumScreen() {
    font-size: 3.5rem;
  }

  @include smallScreen() {
    font-size: 3rem;
  }
}

.vita-scroll {
  font-size: 1.6rem;
  font-weight: normal;
  margin: 1rem 2rem;
}

.vita-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto 7rem;
  padding: 0 30px;
  max-width: 1000px;

  h1 {
    font-size: $largeFont + 2rem;
    font-weight: normal !important;

    @include mediumScreen() {
      font-size: 3.5rem;
    }

    @include smallScreen() {
      font-size: 3rem;
    }
  }

  h2 {
    font-size: 2.65rem;
    font-weight: bold !important;
    margin-bottom: 0;

    @include mediumScreen() {
      font-size: 2.2rem;
    }

    @include smallScreen() {
      font-size: 2rem;
    }
  }

  .vita-text p {
    margin: 3rem 0;
  }

  button {
    background-color: $main;
    min-height: 4rem;
    margin: 1rem;
    flex-wrap: wrap;
    border-radius: 0.2rem;
    padding: 0.5rem 1.5rem;
    color: white;
    font-size: $normalFont;
    font-family: 'Josefin Sans', sans-serif;
  }

  button:hover {
    transition: 0.3s;
    background-color: $lightgreen;
  }
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
