@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.mobile-cutoff {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background: #63bbb0;
  padding: 8px 0 4px;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: none;
  @include mobileView() {
    display: flex;
  }
  .countdown-title {
    font-family: $fontPrimary;
    font-size: $smallFont;
    font-weight: bold;
    margin: 0;
  }
  .countdown {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .countdown-item {
    color: #fff;
    @include mobileView() {
      display: inline-block;
      margin: 0 0.5rem;
    }
    span {
      font-family: $fontPrimary;
      font-size: $smallFont;
      font-weight: bold;
      color: #fff;
      @include mobileView() {
        font-size: $smallFontMobile;
      }
    }
  }
  .time {
    margin-right: 0.5rem;
    &:first-child:last-child {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .time-sp {
    margin: 0 0.3rem;
    vertical-align: middle;
  }
}
section.meal-plan-order-cut-off .content .countdown-item,
.countdown-item {
  &.mod-time {
    display: none;
    @include mobileView() {
      display: inline-block;
    }
  }
}
