@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.steps {
  display: flex;
  padding: 6rem 0 0;
  align-items: center;
  @include smallScreen() {
    padding: 3rem 0 0;
  }

  .current {
    background-color: $main !important;
  }

  // .left {
  //   position: relative;
  //   left: 1px;
  // }

  // .right {
  //   &-one {
  //     right: 1px;
  //     position: relative;
  //   }

  //   &-two {
  //     right: 2px;
  //     position: relative;
  //   }

  //   &-three {
  //     right: 3px;
  //     position: relative;
  //   }
  // }
}

.steps-line {
  width: 10rem;
  height: 2px;
  background-color: $lightgray;
  border: none;
  margin: 0;
  @include smallScreen() {
    width: 6.1rem;
  }
  @media (max-width: 345px) {
    width: 5.6rem;
  }
}

.steps-step {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $lightgray;
  $size: 10px;
  width: $size;
  height: $size;
  border-radius: 100%;

  &.mod-nolink {
    pointer-events: none;
  }
}

.steps-label {
  // top: 1.5px;
  color: $white;
  font-family: 'Josefin Sans';
  font-size: $smallFont;
  line-height: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  color: $main;
  text-align: center;
  margin-bottom: 1.5rem;
  white-space: nowrap;
  @include smallScreen() {
    font-size: $smallerFontMobile;
    margin-bottom: 1rem;
  }
}
