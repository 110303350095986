@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';
@import '../../styles/mixins.scss';

.steps-container {
  .step3-package-title {
    margin-top: 4rem;
    @include mobileView() {
      margin: 2.5rem 0 1rem;
    }
  }
}
