@import '../../../styles/AppDesign.scss';
@import '../../../styles/media-queries.scss';

.gift-cards-info {
  background-color: #ffff;
  padding: 3rem;
  text-align: center;
}
.gift-cards-info-title {
  font-family: $fontSecondary;
  font-size: 3.5rem;
  letter-spacing: 0.125rem;
  color: $main;
}
.gift-cards-info-cards {
  width: 90%;
  max-width: 1920px;
  margin: 3rem auto;
  display: flex;
  @include smallScreen() {
    display: block;
  }
}
.gift-cards-info-card {
  position: relative;
  width: 33.3333%;
  box-sizing: border-box;
  padding: 0 3rem;
  @include smallScreen() {
    width: 100%;
    margin: 0 0 5rem;
  }
}
.gift-cards-info-card-thumb {
  position: relative;
  width: 100%;
  max-width: 150px;
  margin: 0 0 3rem;
}
.gift-cards-info-card-title {
  font-family: 'BebasNeueLight', sans-serif;
  font-size: 3rem;
  max-width: 200px;
  margin: 0 auto 2rem;
  color: $main;
}
.gift-cards-info-card-desc {
  font-size: 1.5rem;
  a {
    color: $main;
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $main;
    }
  }
}
