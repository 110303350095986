@import '../../../styles/AppDesign';

.change-password-form-container {
  .content-container {
    > div {
      margin: 0 0 1rem 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        color: $darkgray;
        font-size: $normalFont;
        font-family:
          Josefin Sans,
          sans-serif;
        width: 40%;
      }

      input {
        border: #c0c0c0 solid 0.1rem;
        text-align: left;
        outline: none;
        background: none;
        min-width: 45%;
        height: 2rem;
        display: flex;
        padding: 0 1rem;
      }
    }

    > .error-text {
      color: red !important;
      margin: 0 !important;
      padding: 0 !important;
      text-align: left;
    }
  }
}
