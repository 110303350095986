@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.meal-plan-banner {
  position: relative;
  background: url() no-repeat center center;
  background-size: cover;
  &:before {
    content: '';
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 28%;
    z-index: 0;
    @include mobileView() {
      display: none;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    font-size: 0;
    pointer-events: all;
    &:before,
    &:after {
      color: $white;
    }
    display: none !important;
    // --swiper-navigation-size:60px;
  }
  .meal-plan-banner-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 30;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    @include mobileView() {
      position: relative;
      height: auto;
      display: block;
      padding: 0;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1920px;
    margin: auto;
    & > * {
      pointer-events: all;
    }

    .section-title,
    h1 {
      font-size: $bigFont;
      font-family: 'BebasNeue';
      font-weight: normal;
      color: $main;
      margin: 0;
    }

    .section-subtitle,
    h2 {
      margin: 0rem;
      font-size: $largeFont;
      font-family: 'Montserrat-Light';
      font-weight: normal;
      font-weight: 500;
      color: $gray;
      margin: 0 0 4rem;
      text-align: center;
      @include largeScreen() {
        margin: 0 0 2.5rem;
      }
    }
    .section-title,
    .section-subtitle,
    h1,
    h2 {
      /* Hide the text while keeping the original position of the button */
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
      user-select: none;
    }

    button {
      font-size: $normalFont;
      font-family:
        Josefin Sans,
        sans-serif;
      font-weight: lighter;
      color: $white;
      background-color: $main;
      padding: 0;
      border: none;
      width: 16rem;
      height: 4rem;
      @include mobileView() {
        height: 4rem;
        width: 12rem;
        font-weight: bold;
      }
    }
    @include mobileView() {
      margin: 0 auto;
      padding: 3rem 0;
      // height:275px;
      height: 400px;
      box-sizing: border-box;
      // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
      justify-content: center;
      // background-color: $white;
      width: 90%;
      max-width: 500px;

      h1 {
        line-height: 1.3;
      }

      h2 {
        margin-bottom: 3rem;
      }
    }
  }
}
.meal-plan-banner-bgs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url() no-repeat center center #eee;
  background-size: cover;
  z-index: 0;
  overflow: hidden;
}
.meal-plan-banner-bg {
  position: relative;
  width: 100%;
  height: 100%;
  background: url() no-repeat center center;
  background-size: cover;
  z-index: 0;
}
.meal-plan-banner-pagination {
  text-align: center;
  padding-bottom: 30px;
  @include mobileView() {
    padding-bottom: 15px;
  }
}
.meal-plan-banner-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  // display:none !important;
  .swiper-pagination-bullet {
    $size: 12px;
    width: $size;
    height: $size;
    opacity: 0.2;
    background: $main;
    margin: 5px;
    @include mobileView() {
      $size: 8px;
      width: $size;
      height: $size;
    }
  }
  .swiper-pagination-bullet-active {
    opacity: 0.9;
    background: $main;
  }
}
section {
  &.meal-plan {
    &-header {
      .content {
        h2 {
          margin-bottom: 4rem;
          text-align: center;
        }
      }
    }
    &-features {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 3.5rem 0;

      .content {
        justify-content: center;
        align-items: center;
        margin: 0;

        .subtitle {
          white-space: pre-line;
          margin-top: 1rem;
          width: 38%;
          text-align: center;
          font-size: $normalFont;
          color: $gray;
        }
      }
      .features {
        margin-top: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        max-width: 1920px;

        .feature {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 20%;

          img {
            width: 48%;
          }

          p {
            font-size: $normalFont;
            width: 60%;
            text-align: center;
          }
        }
      }
    }

    &-what-sets-us-apart {
      background-image: url('/img/healthy.jpg');

      .content {
        justify-content: center;
        align-items: flex-start;
        width: 65%;

        p {
          width: 47.5%;
          font-size: $normalFont;
          line-height: 1.5;
        }

        span {
          display: flex;
          align-items: center;
          font-size: $normalFont;
          font-weight: bold;
          margin-top: 1.5rem;
          color: $main;

          img {
            width: $largeFont;
            margin-right: 1.4rem;
          }
        }
      }
    }

    &-order-cut-off {
      background-color: $main;
      .content {
        align-items: center;
        justify-content: center;

        .countdown-title,
        h1 {
          color: $white;
          text-align: center;
          line-height: 1.5;
        }

        .countdown {
          display: flex;
          width: 60%;
          justify-content: center;
          flex-direction: row;
          margin-top: 3rem;
          &-item {
            width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-right: 1px solid $white;

            span {
              color: $white;
              font-size: $largeFont;

              &.time {
                margin-top: 3rem;
                font-size: $hugeFont;
                font-family: 'Josefin Sans; Thin';
              }
            }

            &.mod-last {
              border: none;
            }
          }
        }
      }
    }
    &-app {
      display: none;
      justify-content: center;
      align-items: center;

      .container {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        width: 45%;
        height: 100%;

        img {
          width: 23%;
          max-width: 200px;
          margin-right: 4rem;
        }

        .content {
          width: 45rem;
          height: 60%;
          text-align: center;
          align-items: center;
          justify-content: space-evenly;
          margin: 0;

          p {
            width: 80%;
            font-size: $normalFont;
            margin-top: 0;
            font-family: 'Josefin Sans';
            line-height: 1.5;
          }

          .appstore {
            display: flex;
            justify-content: space-evenly;
            width: 80%;
            height: 40px;

            a {
              width: 40%;
              max-width: 180px;
            }
            img {
              width: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@include mobileView() {
  section {
    &.meal-plan {
      &-header {
        height: auto;
        max-height: none;
        background-color: $lightergray;
        background-size: cover;
        background-position: top;

        .content {
          margin: 10rem auto;
          padding: 3rem 0;
          //box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
          justify-content: center;
          //background-color: $white;
          width: 90%;
          max-width: 500px;

          h1 {
            line-height: 1.3;
          }

          h2 {
            margin-bottom: 3rem;
          }
        }
      }

      &-features {
        height: auto;
        max-height: none;
        // max-width: 500px;
        margin: auto;

        .section-title,
        h1 {
          margin: 0 auto 0.5rem;
          font-size: $mediumFont;
          font-weight: bold;
        }
        .content {
          margin-top: 0;
          .subtitle {
            font-size: $normalFontMobile;
            width: 80%;
          }
        }

        .features {
          //max-width:300px;
          flex-wrap: wrap;

          .feature {
            width: 25%;
            margin-bottom: 1rem;
            p {
              font-size: $smallFontMobile;
            }
          }
        }
      }

      &-what-sets-us-apart {
        height: auto;
        max-height: none;
        justify-content: center;

        .section-title,
        h1 {
          margin: 0 auto 1rem;
          font-size: $mediumFont;
          font-weight: bold;
        }
        .content {
          align-items: center;
          text-align: center;
          max-width: 500px;
          min-width: 250px;
          margin: 4rem 3rem;

          p {
            width: 100%;
            font-size: $normalFontMobile;
            margin: 0 auto;
          }
        }
      }

      &-order-cut-off {
        @include mobileView {
          display: none;
        }
        .content {
          .countdown {
            width: 100%;
          }
          .countdown-item {
            position: relative;
            border-right: 1px solid rgba(#fff, 0.5);
            // &:after{
            //   content:'';
            //   position:absolute;
            //   right:0;
            //   top:50%;
            //   transform: translateY(-50%);
            //   height:70%;
            //   width:1px;
            //   background:#FFF;
            // }
          }
        }
      }

      &-app {
        @include mobileView {
          display: none;
        }
        height: auto;
        max-height: none;
        background-color: $whitebg;

        .container {
          flex-direction: column;
          margin: 3rem;
          width: 100%;

          .content {
            margin-bottom: 3rem;
            width: auto;

            .section-title,
            h1 {
              margin-bottom: 3rem;
            }

            p {
              width: auto;
              margin-bottom: 3rem;
            }

            .appstore {
              width: auto;
              a {
                width: 40%;
                max-width: 180px;
              }
              img {
                width: 100%;
              }
            }
          }

          img {
            margin: 2rem 0 0;
            width: 60%;
            max-width: 160px;
          }
        }
      }
    }
  }
}

@include smallScreen() {
  section.meal-plan-features {
    max-width: 500px;
  }
  section.meal-plan-features .features .feature {
    width: 50%;
  }
}
