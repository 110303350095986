@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.checkout-success {
  padding: 10rem 0;
  position: relative;
  width: 90%;
  max-width: 1200px;
  font-family: $fontPrimary;
  font-size: $normalFont;
  text-align: center;
  * {
    box-sizing: border-box;
  }
}
.checkout-success-title {
  font-size: $bigFont;
  margin: 0 0 3rem;
}
.checkout-success-separator {
  font-size: $mediumFont;
  margin: 0 0 3rem;
}
.checkout-success-separator {
  display: block;
}
.checkout-home-btn {
  display: block;
  width: 100%;
  max-width: 640px;
  padding: 2rem;
  background: $main;
  color: #fff;
  margin: 0 auto;
  font-size: $largeFont;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  &:hover {
    background: $lightgreen;
  }
}

.checkout-success-desc {
  margin-bottom: 3rem;
}

.checkout-vip {
  border: 1px solid $lightgray;
  max-width: 600px;
  margin: 0 auto 2rem;
  padding: 1rem;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @include smallScreen() {
    padding: 1rem;
  }
}
.checkout-vip-logo {
  display: block;
  position: relative;
  flex: 0 0 auto;
  width: 100px;
  background: url('/img/assets/vip/logo-vip-black-txt-transparent.png')
    no-repeat center center;
  background-size: contain;
  margin-right: 2rem;
  @include smallScreen() {
    width: 70px;
    margin-right: 1rem;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 90%;
  }
}
.checkout-vip-msg {
  line-height: 1.5em;
  color: $main;
  @include smallScreen() {
    font-size: $smallFontMobile;
  }
}
